import React, { useMemo } from 'react';
import { Box } from '@mui/material';

type SocialsProps = {
  company: 'facebook' | 'instagram' | 'youtube' | string;
  style?: any;
};

export default function Socials(props: SocialsProps): React.JSX.Element | null {
  const { company, style } = props;

  const icon = useMemo(() => {
    switch (company) {
      case 'facebook':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            preserveAspectRatio="none"
            x="0px"
            y="0px"
            width="96px"
            height="96px"
            viewBox="0 0 96 96"
            style={style}
          >
            <g id="Layer2_0_FILL">
              <path
                fill="#000000"
                stroke="none"
                d="
M 95.95 48.5
Q 96.05 48.1 95.95 47.7 93.85 39.95 89.7 24.4 89.65 24.05 89.35 23.75 81.8 16.15 72.35 6.65 72.05 6.35 71.6 6.25 57.05 2.3 48.35 0 48.05 -0.1 47.65 0 36.5 3 29.35 4.9 26.55 5.65 24.4 6.25 23.95 6.35 23.65 6.65 15.15 15.15 6.7 23.7 6.35 24 6.25 24.4 2.75 37.5 0.05 47.65 -0.05 48.1 0.05 48.55 3 59.55 6.25 71.8 6.4 72.2 6.7 72.5 16.1 81.95 23.65 89.5 24 89.85 24.4 89.95 27 90.65 29.35 91.3 36.4580078125 93.2125 41.9 94.65
L 41.9 55.9 33.8 55.9 33.8 42.25 41.7 42.25 41.7 32.05
Q 41.55 18.35 57.35 16.7
L 69.85 16.7 69.85 30.35 61.65 30.35
Q 57.6 30.2 57.8 33.5
L 57.8 42.25 69.55 42.25 68.45 56.05 57.95 56.05 57.95 93.65
Q 64.3640625 91.91328125 71.6 89.95 72 89.85 72.35 89.55 79.9 82 89.35 72.5 89.65 72.15 89.7 71.85 90.2 70 91.7 64.35
L 95.95 48.5 Z"
              />
            </g>

            <g id="Layer0_0_FILL">
              <path
                fill="#FFFFFF"
                fill-opacity="0.4980392156862745"
                stroke="none"
                d="
M 69.55 42.25
L 57.8 42.25 57.8 33.5
Q 57.6 30.2 61.65 30.35
L 69.85 30.35 69.85 16.7 57.35 16.7
Q 41.55 18.35 41.7 32.05
L 41.7 42.25 33.8 42.25 33.8 55.9 41.9 55.9 41.9 94.65 47.95 96.25 52.9 95
Q 55.3552734375 94.34296875 57.95 93.65
L 57.95 56.05 68.45 56.05 69.55 42.25 Z"
              />
            </g>
          </svg>
        );
      case 'instagram':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            preserveAspectRatio="none"
            x="0px"
            y="0px"
            width="96px"
            height="96px"
            viewBox="0 0 96 96"
            style={style}
          >
            <g id="Layer1_0_FILL">
              <path
                fill="#000000"
                stroke="none"
                d="
M 95.95 48.5
Q 96.05 48.1 95.95 47.7 93.85 39.95 89.7 24.4 89.65 24.05 89.35 23.75 81.8 16.15 72.35 6.65 72.05 6.35 71.6 6.25 57.05 2.3 48.35 0 48.05 -0.1 47.65 0 36.5 3 29.35 4.9 26.55 5.65 24.4 6.25 23.95 6.35 23.65 6.65 15.15 15.15 6.7 23.7 6.35 24 6.25 24.4 2.75 37.5 0.05 47.65 -0.05 48.1 0.05 48.55 3 59.55 6.25 71.8 6.4 72.2 6.7 72.5 16.1 81.95 23.65 89.5 24 89.85 24.4 89.95 27 90.65 29.35 91.3 40.5 94.3 47.55 96.2 48 96.3 48.4 96.2 58.7 93.45 71.6 89.95 72 89.85 72.35 89.55 79.9 82 89.35 72.5 89.65 72.15 89.7 71.85 90.2 70 91.7 64.35
L 95.95 48.5
M 57.6 21.55
Q 74.5 21.55 74.5 38.45
L 74.5 57.65
Q 74.5 74.55 57.6 74.55
L 38.45 74.55
Q 21.55 74.55 21.55 57.65
L 21.55 38.45
Q 21.55 21.55 38.45 21.55
L 57.6 21.55
M 70 40.1
Q 70 26.1 56 26.1
L 40.1 26.1
Q 26.1 26.1 26.1 40.1
L 26.1 56.05
Q 26.1 70.05 40.1 70.05
L 56 70.05
Q 70 70.05 70 56.05
L 70 40.1
M 48 34.25
Q 53.8 34.25 57.9 38.3 62 42.4 62 48.25 62 54.05 57.9 58.05 53.8 62.25 48 62.25 42.15 62.25 38.05 58.05 34 54.05 34 48.25 34 42.4 38.05 38.3 42.15 34.25 48 34.25
M 60 35.95
Q 59.15 35.1 59.15 33.85 59.15 32.65 60 31.75 60.9 30.85 62.15 30.85 63.35 30.85 64.25 31.75 65.15 32.65 65.15 33.85 65.15 35.1 64.25 35.95 63.35 36.85 62.15 36.85 60.9 36.85 60 35.95
M 57.65 48.25
Q 57.65 44.25 54.85 41.45 52.05 38.7 48.1 38.7 44.1 38.7 41.3 41.45 38.5 44.25 38.5 48.25 38.5 52.2 41.3 54.95 44.1 57.85 48.1 57.85 52.05 57.85 54.85 54.95 57.65 52.2 57.65 48.25 Z"
              />
            </g>

            <g id="Layer0_0_FILL">
              <path
                fill="#FFFFFF"
                fill-opacity="0.4980392156862745"
                stroke="none"
                d="
M 74.5 38.45
Q 74.5 21.55 57.6 21.55
L 38.45 21.55
Q 21.55 21.55 21.55 38.45
L 21.55 57.65
Q 21.55 74.55 38.45 74.55
L 57.6 74.55
Q 74.5 74.55 74.5 57.65
L 74.5 38.45
M 56 26.1
Q 70 26.1 70 40.1
L 70 56.05
Q 70 70.05 56 70.05
L 40.1 70.05
Q 26.1 70.05 26.1 56.05
L 26.1 40.1
Q 26.1 26.1 40.1 26.1
L 56 26.1
M 57.9 38.3
Q 53.8 34.25 48 34.25 42.15 34.25 38.05 38.3 34 42.4 34 48.25 34 54.05 38.05 58.05 42.15 62.25 48 62.25 53.8 62.25 57.9 58.05 62 54.05 62 48.25 62 42.4 57.9 38.3
M 41.3 41.45
Q 44.1 38.7 48.1 38.7 52.05 38.7 54.85 41.45 57.65 44.25 57.65 48.25 57.65 52.2 54.85 54.95 52.05 57.85 48.1 57.85 44.1 57.85 41.3 54.95 38.5 52.2 38.5 48.25 38.5 44.25 41.3 41.45
M 60 31.75
Q 59.15 32.65 59.15 33.85 59.15 35.1 60 35.95 60.9 36.85 62.15 36.85 63.35 36.85 64.25 35.95 65.15 35.1 65.15 33.85 65.15 32.65 64.25 31.75 63.35 30.85 62.15 30.85 60.9 30.85 60 31.75 Z"
              />
            </g>
          </svg>
        );
      case 'youtube':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            preserveAspectRatio="none"
            x="0px"
            y="0px"
            width="96px"
            height="96px"
            viewBox="0 0 96 96"
            style={style}
          >
            <g id="Layer4_0_FILL">
              <path
                fill="#000000"
                stroke="none"
                d="
M 95.95 48.5
Q 96.05 48.1 95.95 47.7 93.85 39.95 89.7 24.4 89.65 24.05 89.35 23.75 81.8 16.15 72.35 6.65 72.05 6.35 71.6 6.25 57.05 2.3 48.35 0 48.05 -0.1 47.65 0 36.5 3 29.35 4.9 26.55 5.65 24.4 6.25 23.95 6.35 23.65 6.65 15.15 15.15 6.7 23.7 6.35 24 6.25 24.4 2.75 37.5 0.05 47.65 -0.05 48.1 0.05 48.55 3 59.55 6.25 71.8 6.4 72.2 6.7 72.5 16.1 81.95 23.65 89.5 24 89.85 24.4 89.95 27 90.65 29.35 91.3 40.5 94.3 47.55 96.2 48 96.3 48.4 96.2 58.7 93.45 71.6 89.95 72 89.85 72.35 89.55 79.9 82 89.35 72.5 89.65 72.15 89.7 71.85 90.2 70 91.7 64.35
L 95.95 48.5
M 83.8 37.25
Q 84.65 47.35 83.8 56.9 84.05 70.05 75.9 71.3 49.7 73.5 23.55 71.3 15.2 70.95 14.65 58.8 14 46.6 14.8 35.8 15.55 24.9 23.55 24.05 49.7 22.15 75.9 24.2 83.3 25.65 83.8 37.25
M 60.95 47
L 42.15 37.25 42.2 56.85 60.95 47 Z"
              />
            </g>

            <g id="Layer1_0_FILL">
              <path
                fill="#FFFFFF"
                fill-opacity="0.4980392156862745"
                stroke="none"
                d="
M 83.8 56.9
Q 84.65 47.35 83.8 37.25 83.3 25.65 75.9 24.2 49.7 22.15 23.55 24.05 15.55 24.9 14.8 35.8 14 46.6 14.65 58.8 15.2 70.95 23.55 71.3 49.7 73.5 75.9 71.3 84.05 70.05 83.8 56.9
M 42.15 37.25
L 60.95 47 42.2 56.85 42.15 37.25 Z"
              />
            </g>
          </svg>
        );
      default:
        return null;
    }
  }, [company, style]);

  return (
    <Box
      sx={{
        width: 'fit-content',
        transform: { xs: 'scale(0.5)', md: 'scale(0.5)' },
      }}
    >
      {icon}
    </Box>
  );
}
