import React, { useCallback, useEffect, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { primary } from '../Theme';
import { ArrowDownward } from '@material-ui/icons';

type Props = {
  sx?: SxProps<Theme>;
  scrollLink: string;
  showMe?: boolean;
};

export default function ScrollDownButton(props: Props): React.JSX.Element {
  const { scrollLink, sx } = props;

  const [showMe, setShowMe] = useState(true);

  const onScroll = useCallback(() => {
    if (showMe) {
      setShowMe(false);
      window.removeEventListener('scroll', onScroll);
    }
  }, [showMe, setShowMe]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <Box
      component={RouterLink}
      to={scrollLink}
      replace={true}
      sx={{
        /*        position: 'fixed',
        left: 0,
        right: 0,
        bottom: '2rem',*/
        pointerEvents: 'auto',
        margin: 'auto',
        width: '3rem',
        height: '3rem',
        borderRadius: '50%',
        background: '#000000',
        svg: { fill: primary },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animation: showMe ? 'blendIn 1s ease forwards' : 'blendOut 0.5s ease forwards',
        ...sx,
      }}
    >
      <Box
        sx={{
          animation: showMe ? 'bounce 1s ease-in infinite' : 'none',
        }}
      >
        <ArrowDownward />
      </Box>
    </Box>
  );
}
