import { Block, BlockImageExt, FlexAlign, IKirbyBlock, IKirbyImage, ResponsiveImage } from '@gk-lab/kirbyreact';

export enum CustomBlockType {
  COOKIERESET = 'cookiereset',
  PREVIEW = 'preview',
  POST = 'post',
  EVENT = 'event',
  VIDEO = 'media',
  GALLERY = 'gallery',
}

export type SocialLink = {
  name: string;
  url: string;
  icon: string;
};

export interface BlockCookieReset extends IKirbyBlock {
  type: CustomBlockType.COOKIERESET;
  text: string;
  variant?: string;
  align?: FlexAlign;
}

export interface BlockPreview extends IKirbyBlock {
  type: CustomBlockType.PREVIEW;
  title?: string;
  blocks: Block[];
  variant?: 'primary' | 'secondary' | 'small';
  position?: {
    vertical?: 'top' | 'center' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
  };
  image?: BlockImageExt;
}

export interface BlockPost extends IKirbyBlock {
  type: CustomBlockType.POST;
  image?: ResponsiveImage;
  title: string;
  subtitle?: string;
  preview?: string;
  text?: string;
  to?: string;
  action?: (Block | CustomBlock)[];
  variant?: 'primary' | 'secondary';
}

export interface BlockVideo extends IKirbyBlock {
  type: CustomBlockType.VIDEO;
  title: string;
  subtitle: string;
  text?: string;
  videourl?: string;
  image?: IKirbyImage;
}

export interface BlockEvent extends IKirbyBlock {
  type: CustomBlockType.EVENT;
  date: string;
  title: string;
  subtitle?: string;
  text?: string;
  past?: boolean;
  today?: boolean;
  variant?: 'primary' | 'secondary';
}

export interface BlockGallery extends IKirbyBlock {
  type: CustomBlockType.GALLERY;
  images: IKirbyImage[];
  rowheight: number;
  columnsXs: number;
  columnsMd: number;
  columnsLg: number;
}

export type CustomBlock = BlockPost | BlockVideo | BlockEvent | BlockPreview | BlockCookieReset | BlockGallery;
