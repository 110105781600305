import React, { useMemo } from 'react';
import { Box, Breakpoint, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { ImagePosition, ResponsiveImage } from '@gk-lab/kirbyreact';

type Props = {
  id: string;
  sx?: SxProps<Theme>;
  backgroundColor?: string;
  background?: ResponsiveImage;
  position?: ImagePosition;
  breakpoint?: Breakpoint;
};

export default function BackgroundImage(props: Props): React.JSX.Element {
  const { id, sx, background, position, breakpoint } = props;

  const bgUrl = useMemo(() => {
    const brk = breakpoint ?? 'xs';
    if (background) {
      if (brk === 'xl' && background.xl) {
        return background.xl.url;
      } else if ((brk === 'xl' || brk === 'lg') && background.lg) {
        return background.lg.url;
      } else if ((brk === 'xl' || brk === 'lg' || brk === 'md') && background.md) {
        return background.md.url;
      } else if ((brk === 'xl' || brk === 'lg' || brk === 'md' || brk === 'sm') && background.sm) {
        return background.sm.url;
      }
      return background.xs.url;
    }
    return null;
  }, [background, breakpoint]);

  return (
    <Box
      id={`${id}-bg`}
      sx={{
        position: 'relative',
        minHeight: '100vh',
        height: '100vh',
        width: '100%',
        background: `url(${bgUrl})`,
        backgroundPosition: position,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        ...sx,
      }}
    />
  );
}
