import React, { useCallback, useMemo, useState } from 'react';
import { AppStatus, ScrollToTop, useAppState, useKirbyData } from '@gk-lab/kirbyreact';
import { Box, Container, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { accent, headerHeight, primary, white } from './Theme';
import { getPath } from './config';
import AppHeader from './components/AppHeader';
import MainNavigation from './components/navigation/MainNavigation';
import Main from './pages/Main';
import { useLocation } from 'react-router-dom';
import { BackButtonProvider } from './context/BackButtonProvider';
import AppBlender from './components/AppBlender';
import AppLoader from './components/AppLoader';
import './styles/animations.css';

function App() {
  const { listedSections, state, header } = useAppState();
  // eslint-disable-next-line
  const kirbyData = useKirbyData(getPath());

  const location = useLocation();

  const [open, setOpen] = useState(false);

  const handleMenuClick = useCallback(() => {
    setOpen((oldValue) => !oldValue);
  }, []);

  const contentLoaded = useMemo(() => {
    // return false
    return state === AppStatus.LOADED;
  }, [state]);

  return (
    <Box
      sx={{
        backgroundColor: '#111111',
      }}
    >
      {contentLoaded && listedSections && (
        <BackButtonProvider>
          <Container
            component={'main'}
            maxWidth={false}
            sx={{
              maxWidth: '1920px',
              opacity: 0,
              animation: contentLoaded ? `fadeIn 2s ease-in 0s normal forwards` : 'none',
            }}
          >
            <div id={'back-to-top-anchor'}></div>
            <Main sections={listedSections} />
          </Container>
          <AppHeader
            open={open}
            showClose={location.pathname !== '/'}
            sx={{
              maxHeight: headerHeight,
              backgroundColor: 'transparent',
              zIndex: 3000,
              padding: { xs: '0', md: '1rem' },
            }}
            data={header}
            socials={kirbyData.data.socials}
            maxWidth={false}
            onMenuClick={handleMenuClick}
          >
            <Container
              maxWidth={false}
              sx={{
                position: 'fixed',
                bottom: 0,
              }}
            >
              {!open && (
                <ScrollToTop
                  boxSx={{
                    position: 'absolute',
                    bottom: '1rem',
                    left: { xs: '1rem' },
                    right: 'unset',
                  }}
                >
                  <Fab
                    color="secondary"
                    size="small"
                    aria-label="scroll back to top"
                    sx={{
                      backgroundColor: accent,
                      borderRadius: 0,
                      '&:hover, &:focus': {
                        backgroundColor: primary,
                        color: white,
                      },
                    }}
                  >
                    <KeyboardArrowUpIcon />
                  </Fab>
                </ScrollToTop>
              )}
            </Container>
            <MainNavigation
              open={open}
              handleClose={() => setOpen(!open)}
              handleClick={() => setOpen(false)}
              mainItems={kirbyData.data?.navitems}
              metaItems={kirbyData.data?.metaitems}
              socials={kirbyData.data?.sociallinks}
            />
          </AppHeader>
        </BackButtonProvider>
      )}
      <AppBlender loaded={contentLoaded} />
      <AppLoader loaded={contentLoaded} />
    </Box>
  );
}

export default App;
