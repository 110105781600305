import React, { useMemo } from 'react';
import { Box, Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { Block } from '@gk-lab/kirbyreact/dist/esm/types/Block';
import { getBlockElement } from '../utils/sectionUtils';
import { SectionDefault } from '../types/Section';
import BottomCrown from '../svg/BottomCrown';

type Props = {
  data: SectionDefault;
  blocks?: Block[];
  sx?: SxProps<Theme>;
  showDetails?: boolean;
};

export default function FooterView(props: Props): React.JSX.Element {
  const { sx, data, showDetails } = props;
  const { blocks, previewCount, url } = data;

  const max = useMemo<number>(() => {
    if (showDetails || !previewCount || (previewCount ?? 0) === 0) {
      return Infinity;
    }
    return previewCount;
  }, [showDetails, previewCount]);

  return (
    <Stack
      className={`footer content ${url.replace('/', '-')}`}
      flexDirection={'column'}
      sx={{
        ...sx,
      }}
    >
      <Box
        id={'top'}
        sx={{
          display: 'flex',
          position: 'relative',
          width: '100%',
          height: 'fit-content',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <BottomCrown />
        </Box>
      </Box>
      <Stack
        flexDirection={'column'}
        gap={4}
        sx={{
          py: {
            xs: '6rem',
            md: '6rem',
          },
          px: {
            xs: '1rem',
            md: '2rem',
            lg: '6rem',
          },
        }}
      >
        {blocks?.map((block, idx) => (idx < max ? getBlockElement(block, idx, 'section') : null))}
      </Stack>
    </Stack>
  );
}
