import React from 'react';

export default function LogoCopy() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 1700 300"
    >
      <g id="Layer2_0_FILL">
        <path
          fill="#000000"
          stroke="none"
          d="
M 17 21
L 23 280.95 1669.95 277.95 1675.95 18 17 21 Z"
        />
      </g>

      <g id="Layer1_0_FILL">
        <path
          fill="#ECD693"
          stroke="none"
          d="
M 194 47.95
L 134 47.95 134 247.95 194 247.95 194 47.95
M 120 47.95
L 60 47.95 60 247.95 120 247.95 120 47.95
M 268.5 47.95
L 208.5 47.95 208.5 247.95 268.5 247.95 268.5 47.95
M 362.5 190.95
L 348 190.95 348 47.95 288 47.95 288 247.95 426.5 247.95 426.5 47.95 362.5 47.95 362.5 190.95
M 520 132.95
L 520 187.95 448.5 187.95 448.5 247.95 580 247.95 580 132.95 520 132.95
M 508.5 162.95
L 508.5 107.95 579.95 107.95 579.95 47.95 448.45 47.95 448.45 107.95 448.5 107.95 448.5 162.95 508.5 162.95
M 662 247.95
L 662 47.95 602 47.95 602 247.95 662 247.95
M 742 187.95
L 742 107.95 797 107.95 797 47.95 682 47.95 682 247.95 797 247.95 797 187.95 742 187.95
M 879 47.95
L 819 47.95 819 247.95 879 247.95 879 47.95
M 953 47.95
L 893 47.95 893 247.95 953 247.95 953 47.95
M 1027.5 247.95
L 1027.5 47.95 967.5 47.95 967.5 247.95 1027.5 247.95
M 1185 47.95
L 1046.75 47.95 1046.75 247.95 1185 247.95 1185 47.95
M 1106.75 107.95
L 1121.15 107.95 1121.15 187.95 1106.75 187.95 1106.75 107.95
M 1341.45 247.95
L 1341.45 47.95 1267 47.95 1267 43 1207 43 1207 47.95 1206.95 47.95 1206.95 107.95 1207 107.95 1207 248 1267 248 1267 107.95 1281.45 107.95 1281.45 247.95 1341.45 247.95
M 1482 162.15
L 1482 134.15 1499.65 134.15 1499.65 47.95 1439.65 47.95 1439.65 122.95 1421.15 122.95 1421.15 47.95 1361.15 47.95 1361.15 247.95 1421.15 247.95 1421.15 172.95 1439.65 172.95 1439.65 247.95 1499.65 247.95 1499.65 162.15 1482 162.15
M 1650.55 247.95
L 1650.55 132.95 1590.55 132.95 1590.55 187.95 1519.05 187.95 1519.05 247.95 1650.55 247.95
M 1650.5 107.95
L 1650.5 47.95 1519 47.95 1519 107.95 1519.05 107.95 1519.05 162.95 1579.05 162.95 1579.05 107.95 1650.5 107.95 Z"
        />
      </g>
    </svg>
  );
}
