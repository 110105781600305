import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlockPost } from '../../types/Block';
import { Box, Button, Stack, Typography } from '@mui/material';
import { BlockType, KirbyImage, KirbyProps } from '@gk-lab/kirbyreact';
import PostBackground from '../PostBackground';
import { getBlockElement } from '../../utils/sectionUtils';
import { ccn } from '../../utils/utils';

interface Props extends KirbyProps {
  data: BlockPost;
}

export default function Post(props: Props): React.JSX.Element {
  const { data } = props;
  const navigate = useNavigate();
  const [showDetail, setShowDetail] = useState(!data.preview);

  const image = useMemo(() => {
    if (data?.image && data?.image.xs.url) {
      return (
        <KirbyImage
          data={{
            type: BlockType.IMAGE,
            image: data.image,
          }}
          sx={{
            display: 'flex',
            position: 'relative',
            ...props.sx,
          }}
          className={'image'}
        />
      );
    }
    return null;
  }, [data, props.sx]);

  return (
    <Box className={ccn(['post', data?.variant, props.className])}>
      <PostBackground className={ccn([data?.variant])}>
        <Box className={'wrapper'} onClick={data?.to ? () => navigate(`/${data?.to}`) : undefined}>
          <Stack className={'content'}>
            {data?.subtitle && (
              <Typography variant={'h5'} className={'subtitle'}>
                {data?.subtitle}
              </Typography>
            )}
            <Typography variant={'h3'} className={'title'}>
              {data?.title}
            </Typography>
            {image}
            {!showDetail && data?.preview && (
              <Typography className={'text'} dangerouslySetInnerHTML={{ __html: data.preview }} />
            )}
            {showDetail && data?.text && (
              <Typography className={'text'} dangerouslySetInnerHTML={{ __html: data.text }} />
            )}
            {data?.preview && (
              <Button variant={'text'} onClick={() => setShowDetail((oldValue) => !oldValue)}>
                {showDetail ? '- Weniger' : '+ Weiterlesen'}
              </Button>
            )}
            {data?.action && data?.action[0] && getBlockElement(data?.action[0], 0, 'post')}
          </Stack>
        </Box>
      </PostBackground>
    </Box>
  );
}
