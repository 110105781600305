import React, { createContext, PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

enum ACTIONS {
  PUSH_HISTORY = 'PUSH_HISTORY',
}

type InitialStateType = {
  history: (string | undefined)[];
};

type PayloadType = {
  type: ACTIONS;
  payload?: string;
};

const initialState: InitialStateType = {
  history: [],
};

const BackButtonContext = createContext<InitialStateType>(initialState);
const BackButtonDispatchContext = createContext<React.Dispatch<any>>(() => null);

const pushHistory = (pathname: string) => {
  return {
    type: ACTIONS.PUSH_HISTORY,
    payload: pathname,
  };
};

const BackButtonReducer = (state: InitialStateType, action: PayloadType) => {
  switch (action.type) {
    case ACTIONS.PUSH_HISTORY: {
      const pathname = action.payload;
      const last = state.history.length > 0 ? state.history[state.history.length - 1] : undefined;
      if (!last || (last && pathname !== last)) {
        return {
          ...state,
          history: [...state.history, pathname],
        };
      } else {
        return state;
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

function BackButtonProvider(props: PropsWithChildren): React.JSX.Element {
  const [state, dispatch] = React.useReducer(BackButtonReducer, initialState);

  const location = useLocation();

  useEffect(() => {
    dispatch(pushHistory(location.pathname));
  }, [location.pathname]);

  return (
    <BackButtonContext.Provider value={state}>
      <BackButtonDispatchContext.Provider value={dispatch}>{props.children}</BackButtonDispatchContext.Provider>
    </BackButtonContext.Provider>
  );
}

function useBackButton(): { canGoBack: boolean } {
  const context = React.useContext(BackButtonContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return {
    canGoBack: context.history.length > 1,
  };
}

function useBackState() {
  const context = React.useContext(BackButtonContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return context;
}

function useBackDispatch() {
  const context = React.useContext(BackButtonDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }
  return context;
}

export { BackButtonProvider, useBackState, useBackDispatch, useBackButton, pushHistory };
