import { Breakpoints, CSSObject } from '@mui/material';
import { primary, primaryDark, secondaryLight } from '../Theme';

export const p = (breakpoints: Breakpoints): CSSObject => ({
  fontSize: '1.25rem',
  fontWeight: 300,
  lineHeight: 1.3,
  margin: 0,
  position: 'relative',
  color: secondaryLight,
  [breakpoints.up('md')]: {
    fontSize: '1.625rem',
  },
  a: {
    color: primary,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  'a:hover': {
    color: primaryDark,
    textDecoration: 'underline',
  },
  '& p, a': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    margin: 'inherit',
    marginBottom: '1rem',
  },
  figure: {
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
  },
  img: {
    width: '100%',
  },
});
