import React from 'react';

export default function Tribute() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 1024 140"
    >
      <g id="Layer3_0_FILL">
        <path
          fill="#000000"
          stroke="none"
          d="
M 1014.4 131.4
L 1015.4 13.05 10.75 7.85 8.1 126.9 1014.4 131.4 Z"
        />
      </g>

      <g id="Layer1_0_FILL">
        <path
          fill="#ECD693"
          stroke="none"
          d="
M 75 42.9
L 45.8 42.9 45.8 52.1 54.2 52.1 54.2 98.8 66.65 98.8 66.65 52.1 75 52.1 75 42.9
M 98.3 42.9
L 82.9 42.9 82.9 98.8 95.25 98.8 95.25 73.75 98.75 73.75 104.9 98.8 117.65 98.8 110 71.75
Q 112.8 70.45 114.25 68.5 115.75 66.5 116.3 63.85 116.8 61.15 116.8 57.65 116.8 51.8 114.55 48.6 112.2 45.4 108.05 44.15 103.95 42.9 98.3 42.9
M 104.8 63
Q 104.2 64.75 102.75 65.7 101.3 66.65 98.7 66.65
L 95.25 66.65 95.25 51.2 99.25 51.2
Q 102.75 51.2 104.1 53.2 105.4 55.2 105.4 58.75 105.4 61.25 104.8 63
M 139.75 42.9
L 127.35 42.9 127.35 98.8 139.75 98.8 139.75 42.9
M 172.4 43.5
Q 169.15 42.9 165.6 42.9
L 151.05 42.9 151.05 98.8 168.25 98.8
Q 172.3 98.8 175.45 97.8 178.55 96.9 180.75 94.85 182.85 92.85 184 89.75 185.15 86.6 185.15 82.2 185.15 78.25 184 75.4 182.85 72.6 180.65 70.9 178.45 69.2 175.2 68.8 177.75 68.3 179.6 66.95 181.4 65.55 182.4 63.2 183.4 60.95 183.4 57.55 183.4 52.85 181.95 50 180.55 47.1 178.1 45.55 175.6 44 172.4 43.5
M 163.4 51.2
L 165.55 51.2
Q 167.9 51.2 169.45 51.95 171 52.7 171.75 54.2 172.55 55.7 172.55 57.9 172.55 60.2 172.05 61.85 171.55 63.55 170.1 64.4 168.65 65.3 165.8 65.3
L 163.4 65.3 163.4 51.2
M 163.4 73.4
L 165.95 73.4
Q 168.65 73.4 170.2 74.25 171.8 75.15 172.5 77 173.2 78.85 173.2 82 173.2 86.05 171.65 88.1 170.1 90.2 166 90.2
L 163.4 90.2 163.4 73.4
M 227.3 42.9
L 215 42.9 214.8 86.8
Q 214.55 88.5 213.65 89.65 212.7 90.7 210.75 90.7 208.8 90.7 207.85 89.65 206.95 88.5 206.65 86.8
L 206.4 83.35 206.4 42.9 194.2 42.9 194.2 81.5
Q 194.2 87.35 195.65 91.4 197.1 95.45 200.7 97.5 204.35 99.65 210.75 99.65 217.15 99.65 220.75 97.5 224.35 95.45 225.85 91.4 227.3 87.35 227.3 81.5
L 227.3 42.9
M 284.2 51.35
L 296.95 51.35 296.95 42.9 271.85 42.9 271.85 98.8 297.1 98.8 297.1 90.45 284.2 90.45 284.2 73.75 293.9 73.75 293.9 65.2 284.2 65.2 284.2 51.35
M 243.15 98.8
L 255.6 98.8 255.6 52.1 263.95 52.1 263.95 42.9 234.75 42.9 234.75 52.1 243.15 52.1 243.15 98.8
M 331.3 98.8
L 343.75 98.8 343.75 52.1 352.1 52.1 352.1 42.9 322.9 42.9 322.9 52.1 331.3 52.1 331.3 98.8
M 376 42.15
Q 369.75 42.15 366.05 44.3 362.4 46.5 360.8 50.55 359.2 54.65 359.2 60.45
L 359.2 81.2
Q 359.2 86.95 360.8 91.1 362.4 95.25 366.05 97.4 369.75 99.65 376 99.65 382.35 99.65 386.05 97.4 389.75 95.25 391.35 91.1 392.9 86.95 392.9 81.2
L 392.9 60.45
Q 392.9 54.65 391.35 50.55 389.75 46.5 386.05 44.3 382.35 42.15 376 42.15
M 380 54.25
L 380.3 57.4 380.3 84.3 380 87.4
Q 379.75 88.95 378.85 89.9 377.9 90.85 376 90.85 374.1 90.85 373.2 89.9 372.35 88.95 372.1 87.4
L 371.85 84.3 371.85 57.4 372.1 54.25
Q 372.4 52.8 373.3 51.8 374.2 50.85 376 50.85 377.9 50.85 378.85 51.8 379.75 52.8 380 54.25
M 449.9 47.6
Q 448.45 45.2 445.75 43.7 443 42.15 438.55 42.15 431.3 42.15 427.1 45.9 422.85 49.7 422.85 56.8 422.85 61.8 425.05 65.25 427.2 68.65 430.4 71.35
L 435.25 75.55
Q 438.05 78 439.7 80.35 441.35 82.75 441.35 86.15 441.35 87.6 440.8 88.95 440.2 90.25 438.25 90.25 436.75 90.25 435.65 89.25 434.6 88.3 434 86.1 433.4 83.9 433.35 80.3
L 422.8 81.9
Q 423 88.1 424.8 92 426.6 95.9 430.1 97.75 433.65 99.65 438.85 99.65 443.35 99.65 446.65 97.75 449.9 95.9 451.7 92.7 453.5 89.5 453.5 85.55 453.5 79.75 451 75.8 448.45 71.9 444.8 68.8
L 440.2 64.7
Q 437.8 62.6 436.25 60.6 434.8 58.65 434.8 55.55 434.8 54.1 435.6 52.65 436.4 51.2 438.15 51.2 439.7 51.2 440.45 52.2 441.25 53.2 441.45 55.05
L 441.75 59.35 452.4 58.05
Q 452.35 55.55 451.8 52.8 451.3 50.1 449.9 47.6
M 487.75 98.8
L 487.75 90.45 474.85 90.45 474.85 73.75 484.55 73.75 484.55 65.2 474.85 65.2 474.85 51.35 487.6 51.35 487.6 42.9 462.5 42.9 462.5 98.8 487.75 98.8
M 521.4 42.9
L 496.3 42.9 496.3 98.8 521.55 98.8 521.55 90.45 508.65 90.45 508.65 73.75 518.35 73.75 518.35 65.2 508.65 65.2 508.65 51.35 521.4 51.35 521.4 42.9
M 555.2 42.9
L 530.1 42.9 530.1 98.8 555.35 98.8 555.35 90.45 542.45 90.45 542.45 73.75 552.15 73.75 552.15 65.2 542.45 65.2 542.45 51.35 555.2 51.35 555.2 42.9
M 620.55 80.8
L 620.55 85.9 640.8 89.75 640.8 85.5 636.55 84.8 636.55 81.85 640.8 81.15 640.8 77.05 620.55 80.8
M 633.85 82.25
L 633.85 84.4 625.95 83.35 633.85 82.25
M 640.8 67.05
L 640.8 64 620.55 64 620.55 67.7 630.3 67.7 620.55 71.85 620.55 75 640.8 75 640.8 71.2 630.3 71.2 640.8 67.05
M 589.45 44.75
Q 585.35 42.9 578.05 42.9
L 563.9 42.9 563.9 98.8 578.2 98.8
Q 585.4 98.8 589.45 96.95 593.5 95.05 595.15 91.1 596.8 87.15 596.8 80.9
L 596.8 60.55
Q 596.8 54.35 595.15 50.45 593.5 46.6 589.45 44.75
M 576.25 51.55
L 578.1 51.55
Q 580.85 51.55 582.1 52.25 583.35 52.9 583.75 54.45 584.1 55.95 584.1 58.45
L 584.1 82.8 583.8 86.95
Q 583.5 88.6 582.25 89.4 581 90.25 578.2 90.25
L 576.25 90.25 576.25 51.55
M 634.3 49.05
L 626.95 49.05
Q 624.7 49.05 623.3 49.65 621.9 50.3 621.2 51.75 620.55 53.2 620.55 55.85
L 620.55 61 640.8 61 640.8 55.8
Q 640.8 53.2 640.15 51.75 639.45 50.3 638 49.65 636.6 49.05 634.3 49.05
M 635 53.65
L 636.5 53.8
Q 637.1 53.9 637.4 54.35 637.7 54.8 637.7 55.8
L 637.7 56.55 623.65 56.55 623.65 55.85
Q 623.65 54.85 623.95 54.4 624.15 53.95 624.7 53.8 625.25 53.65 626.2 53.65
L 635 53.65
M 679.35 42.9
L 660.6 42.9 660.6 98.8 672.95 98.8 672.95 75.05 677.9 75.05
Q 682.45 75.05 686.15 73.75 689.75 72.45 691.95 69 694.05 65.6 694.05 59.1 694.05 53.95 692.45 50.3 690.9 46.7 687.65 44.8 684.4 42.9 679.35 42.9
M 682.15 55.15
L 682.45 58.9
Q 682.45 61.45 682.1 63.1 681.7 64.75 680.55 65.6 679.35 66.4 677.05 66.4
L 672.95 66.4 672.95 51.6 677 51.6
Q 679.5 51.6 680.65 52.6 681.85 53.55 682.15 55.15
M 728.05 42.9
L 702.95 42.9 702.95 98.8 728.2 98.8 728.2 90.45 715.3 90.45 715.3 73.75 725 73.75 725 65.2 715.3 65.2 715.3 51.35 728.05 51.35 728.05 42.9
M 762.55 42.9
L 733.35 42.9 733.35 52.1 741.75 52.1 741.75 98.8 754.2 98.8 754.2 52.1 762.55 52.1 762.55 42.9
M 795.55 42.9
L 770.45 42.9 770.45 98.8 795.7 98.8 795.7 90.45 782.8 90.45 782.8 73.75 792.5 73.75 792.5 65.2 782.8 65.2 782.8 51.35 795.55 51.35 795.55 42.9
M 829.4 44.15
Q 825.3 42.9 819.65 42.9
L 804.25 42.9 804.25 98.8 816.6 98.8 816.6 73.75 820.1 73.75 826.25 98.8 839 98.8 831.35 71.75
Q 834.15 70.45 835.6 68.5 837.1 66.5 837.65 63.85 838.15 61.15 838.15 57.65 838.15 51.8 835.9 48.6 833.55 45.4 829.4 44.15
M 820.6 51.2
Q 824.1 51.2 825.45 53.2 826.75 55.2 826.75 58.75 826.75 61.25 826.15 63 825.55 64.75 824.1 65.7 822.65 66.65 820.05 66.65
L 816.6 66.65 816.6 51.2 820.6 51.2
M 891.95 73.95
L 891.95 65.35 881.65 65.35 881.65 51.25 893.45 51.25 893.45 42.9 869.3 42.9 869.3 98.8 881.65 98.8 881.65 73.95 891.95 73.95
M 918.2 42.15
Q 911.95 42.15 908.25 44.3 904.6 46.5 903 50.55 901.4 54.65 901.4 60.45
L 901.4 81.2
Q 901.4 86.95 903 91.1 904.6 95.25 908.25 97.4 911.95 99.65 918.2 99.65 924.55 99.65 928.25 97.4 931.95 95.25 933.55 91.1 935.1 86.95 935.1 81.2
L 935.1 60.45
Q 935.1 54.65 933.55 50.55 931.95 46.5 928.25 44.3 924.55 42.15 918.2 42.15
M 922.2 54.25
L 922.5 57.4 922.5 84.3 922.2 87.4
Q 921.95 88.95 921.05 89.9 920.1 90.85 918.2 90.85 916.3 90.85 915.4 89.9 914.55 88.95 914.3 87.4
L 914.05 84.3 914.05 57.4 914.3 54.25
Q 914.6 52.8 915.5 51.8 916.4 50.85 918.2 50.85 920.1 50.85 921.05 51.8 921.95 52.8 922.2 54.25
M 954.1 42.9
L 942.45 42.9 952.75 68.7 941.9 98.8 952.15 98.8 958.15 82.1 964.95 98.8 976.55 98.8 965.65 71.95 975.5 42.9 965.2 42.9 960.2 58.05 954.1 42.9 Z"
        />
      </g>

      <path
        id="Layer2_0_1_STROKES"
        stroke="#ECD693"
        stroke-width="6"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="3"
        fill="none"
        d="
M 25.15 21.05
L 999.15 23.8 996.9 120.2 25.15 116.6 25.15 21.05 Z"
      />
    </svg>
  );
}
