import { black, primary } from '../Theme';
import { Breakpoints, CSSObject } from '@mui/material';

export const backgrounds = (breakpoints: Breakpoints): CSSObject => ({
  '.background': {
    '.bg-box-outer': {
      backgroundColor: black,
    },
    '.bg-box-inner': {
      backgroundColor: black,
      border: `3px solid ${primary}`,
    },
    '&.primary': {
      '.bg-box-outer': {
        backgroundColor: primary,
      },
      '.bg-box-inner': {
        backgroundColor: primary,
        border: `4px solid ${black}`,
      },
    },
  },
});
