import { Breakpoint, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

export default function useMuiBreakpoint(): Breakpoint {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>('xs');

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(() => {
    if (matchXl) setBreakpoint('xl');
    else if (matchLg) setBreakpoint('lg');
    else if (matchMd) setBreakpoint('md');
    else if (matchSm) setBreakpoint('sm');
    else setBreakpoint('xs');
  }, [matchSm, matchMd, matchLg, matchXl]);

  return breakpoint;
}
