import React from 'react';
import { Button, Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { getBlockElement } from '../utils/sectionUtils';
import { Link as RouterLink } from 'react-router-dom';
import { SectionEvents } from '../types/Section';

type Props = {
  data: SectionEvents;
  sx?: SxProps<Theme>;
  showDetails?: boolean;
};

export default function EventsView(props: Props): React.JSX.Element {
  const { sx, data } = props;
  const { blocks, events, url, align, cta, filtered } = data;

  return (
    <Stack
      className={`content events ${url.replace('/', '-')}`}
      sx={{
        flexDirection: 'column',
        alignItems: align ?? 'center',
        width: '100%',
        gap: 4,
        py: {
          xs: '6rem',
          md: '6rem',
        },
        px: {
          xs: '1rem',
          md: '4rem',
          lg: '6rem',
        },
        ...sx,
      }}
    >
      {blocks?.map((block, idx) => getBlockElement(block, idx, 'section'))}
      {events?.map((block, idx) => getBlockElement(block, idx, 'section'))}
      {filtered && (
        <Button component={RouterLink} to={`/${url}`} sx={{}}>
          {cta ?? 'Mehr'}
        </Button>
      )}
    </Stack>
  );
}
