import React, { PropsWithChildren, useMemo } from 'react';
import { BlockImageExt, getClassName, KirbyImage, KirbyProps, parseCss } from '@gk-lab/kirbyreact';
import { BlockPreview } from '../types/Block';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface Props extends KirbyProps {
  data: BlockPreview;
}

const Wrapper = (
  props: PropsWithChildren<{
    image?: BlockImageExt;
    sx?: SxProps<Theme>;
  }>
): React.JSX.Element => {
  if (props.image) {
    return (
      <KirbyImage
        className={`preview-wrapper`}
        data={props.image}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...props.sx,
        }}
      >
        {props.children}
      </KirbyImage>
    );
  }
  return (
    <Box
      className={`preview-wrapper`}
      sx={{
        display: 'flex',
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
};

export default function Preview(props: Props): React.JSX.Element {
  const { data, children } = props;

  const sx: SxProps<Theme> = useMemo(() => {
    let hor = 'center';
    if (data.position?.horizontal === 'left') {
      hor = 'flex-start';
    } else if (data.position?.horizontal === 'right') {
      hor = 'flex-end';
    }
    let ver = 'center';
    if (data.position?.vertical === 'top') {
      ver = 'flex-start';
    } else if (data.position?.vertical === 'bottom') {
      ver = 'flex-end';
    }

    return {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: hor,
      alignItems: ver,
    };
  }, [data.position]);

  return (
    <Box className={getClassName(data, 'preview', 'container')}>
      <Wrapper
        image={data.image}
        sx={{
          ...sx,
          ...(data.css && parseCss(data.css)),
        }}
      >
        {children}
      </Wrapper>
    </Box>
  );
}
