import React, { MouseEvent, PropsWithChildren } from 'react';
import { Button, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { useBackButton } from '../context/BackButtonProvider';

type Props = {
  forceUrl?: string;
  fallbackUrl?: string;
  sx?: SxProps<Theme>;
  onClick?(e: MouseEvent): void;
  options?: NavigateOptions;
  ariaLabel?: string;
};

export default function BackButton(props: PropsWithChildren<Props>): React.JSX.Element {
  const { onClick, fallbackUrl, forceUrl, options, ariaLabel, children, sx } = props;
  const navigate = useNavigate();
  const { canGoBack } = useBackButton();

  function handleClick(event: MouseEvent) {
    if (onClick) {
      onClick(event);
    } else {
      if (forceUrl) {
        navigate(forceUrl, options ?? { replace: false });
      } else if (canGoBack) {
        navigate(-1);
      } else if (fallbackUrl) {
        navigate(fallbackUrl, options ?? { replace: false });
      }
    }
  }

  return (
    <Button
      className={'back-button'}
      onClick={handleClick}
      startIcon={!Boolean(children) ? <CloseIcon /> : undefined}
      sx={{ ...sx }}
      aria-label={ariaLabel ?? 'Zurück'}
    >
      {children}
    </Button>
  );
}
