import React from 'react';

export default function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      width="96px"
      height="96px"
      viewBox="0 0 96 96"
    >
      <g id="Layer5_0_FILL">
        <path
          fill="#000000"
          stroke="none"
          d="
M 95.95 48.5
Q 96.05 48.1 95.95 47.7 93.85 39.95 89.7 24.4 89.65 24.05 89.35 23.75 81.8 16.15 72.35 6.65 72.05 6.35 71.6 6.25 57.05 2.3 48.35 0 48.05 -0.1 47.65 0 36.5 3 29.35 4.9 26.55 5.65 24.4 6.25 23.95 6.35 23.65 6.65 15.15 15.15 6.7 23.7 6.35 24 6.25 24.4 2.75 37.5 0.05 47.65 -0.05 48.1 0.05 48.55 3 59.55 6.25 71.8 6.4 72.2 6.7 72.5 16.1 81.95 23.65 89.5 24 89.85 24.4 89.95 27 90.65 29.35 91.3 40.5 94.3 47.55 96.2 48 96.3 48.4 96.2 58.7 93.45 71.6 89.95 72 89.85 72.35 89.55 79.9 82 89.35 72.5 89.65 72.15 89.7 71.85 90.2 70 91.7 64.35
L 95.95 48.5 Z"
        />
      </g>

      <g id="Layer4_0_FILL">
        <path
          fill="#EBD593"
          stroke="none"
          d="
M 89.7 27.9
Q 89.65 27.55 89.35 27.25 81.8 19.65 72.35 10.15 72.05 9.85 71.6 9.75 57.05 5.8 48.35 3.5 48.05 3.4 47.65 3.5 36.5 6.5 29.35 8.4 26.55 9.15 24.4 9.75 23.95 9.85 23.65 10.15 15.15 18.65 6.7 27.2 6.35 27.5 6.25 27.9 2.9728515625 40.165625 0.4 49.85 3.190625 60.26875 6.25 71.8 6.4 72.2 6.7 72.5 16.1 81.95 23.65 89.5 24 89.85 24.4 89.95 27 90.65 29.35 91.3 40.5 94.3 47.55 96.2 48 96.3 48.4 96.2 58.7 93.45 71.6 89.95 72 89.85 72.35 89.55 79.9 82 89.35 72.5 89.65 72.15 89.7 71.85 90.2 70 91.7 64.35
L 95.55 49.85
Q 93.5048828125 42.156640625 89.7 27.9 Z"
        />
      </g>

      <g id="Layer3_0_FILL">
        <path
          fill="#000000"
          stroke="none"
          d="
M 75.5 36.95
L 75.5 20.7 62.05 25.8
Q 61.9 25.85 61.7 25.85 61.5 25.8 61.35 25.7 59.35 24.55 54.15 21.45
L 47.85 17.7
Q 37.6 23.8 34.4 25.7 34.3 25.8 34.1 25.85 33.9 25.85 33.75 25.8 33.4 25.7 29.35 24.15 27.45 23.4 24.7 22.35
L 20.25 20.65 20.25 36.95 75.5 36.95 Z"
        />
      </g>

      <g id="Layer2_0_FILL">
        <path
          fill="#000000"
          stroke="none"
          d="
M 75.5 39.7
L 58.9 39.7 58.9 78.15 59.1 78.15 75.1 72.85
Q 75.35 72.75 75.45 72.6 75.5 72.5 75.5 72.25
L 75.5 39.7 Z"
        />
      </g>

      <g id="Layer1_0_FILL">
        <path
          fill="#000000"
          stroke="none"
          d="
M 56.15 39.7
L 39.6 39.7 39.6 78.2 56.15 78.2 56.15 39.7 Z"
        />
      </g>

      <g id="Layer0_0_FILL">
        <path
          fill="#000000"
          stroke="none"
          d="
M 36.8 39.7
L 20.25 39.7 20.25 72.7 36.8 78.2 36.8 39.7 Z"
        />
      </g>
    </svg>
  );
}
