import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SxProps, Theme } from '@mui/system';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ccn } from '../../utils/utils';

export default function MainNavigationLink(props: {
  url: string;
  copy: string;
  onClick?(): void;
  scope?: 'main' | 'footer';
  sx?: SxProps<Theme>;
}) {
  const { pathname, hash } = useLocation();

  const matchUrl = useMemo(() => {
    if (props.url) {
      let url = props.url;
      if (url.indexOf('/') === 0) url = url.slice(1);
      return pathname.includes(url) || hash.includes(url);
    }
    return false;
  }, [props.url, pathname, hash]);

  return (
    <Button
      component={RouterLink}
      className={ccn(['main-navigation', props.scope, matchUrl ? 'active' : ''])}
      onClick={props.onClick}
      sx={{ ...props.sx }}
      to={props.url}
    >
      {props.copy}
    </Button>
  );
}
