import { black, primary, primaryDark, secondaryDark } from '../Theme';
import { Breakpoints, CSSObject } from '@mui/material';

export const content = (breakpoints: Breakpoints): CSSObject => ({
  '.post, .event, .video': {
    position: 'relative',
    backgroundColor: `transparent`,
    width: '100%',
    color: primary,

    '.title, .subtitle, .text': {
      color: 'inherit',
      figure: {
        margin: 0,
        padding: 0,
      },
      img: {
        width: '100%',
      },
    },

    '.date': {
      color: black,
      background: primary,
      padding: '0.5rem',
      marginLeft: '-0.5rem',
      marginRight: '-0.5rem',
      width: 'fit-content',
    },

    '.content': {
      rowGap: '0.5rem',
      padding: '2rem',
    },

    '.MuiButton-root': {
      padding: '0.5rem 1rem',
    },

    '&.primary': {
      color: black,

      'p, a': {
        color: black,
      },

      '.date': {
        color: primary,
        background: black,
      },

      '.MuiButton-root': {
        color: black,
        border: `2px solid ${black}`,
      },
    },
  },
  '.event': {
    maxWidth: '512px',
  },
  '.post, .video': {
    maxWidth: '768px',
  },
  '.page': {
    '&.musicmonks-info': {
      justifyItems: 'flex-end',
      alignContent: 'flex-end',
    },
  },
  '.app-bar': {
    '.center-content': {
      '.back-button': {
        background: 'rgba(0,0,0,0.6)',
        padding: '0.5rem 0.75rem !important',
        '&:hover, &:focus, &:active': {
          background: 'rgba(0,0,0,0.9)',
        },
      },
    },
  },
  section: {
    '&.welcome': {
      '> .content': {
        rowGap: '2rem',
        gap: 16,
        maxWidth: '100%',
        '&.welcome': {
          minHeight: '105vh',
          paddingBottom: '10vh',
        },
        '&.musicmonks, &.news, &.dates, &.footer': {},
        '&.musicmonks': {},
        '&.footer': {
          background: '#000000',
        },
        '&.gallery': {
          paddingBottom: '20rem',
        },
        '&.dates': {},
      },
    },
    '&.detail': {
      '> .content': {
        rowGap: '0.5rem',
        gap: 16,
        paddingTop: '10rem',
        paddingBottom: '10rem',
        maxWidth: '1024px',
        '&.musicmonks-info, &.impressum, &.booking': {
          justifySelf: 'center',
        },
        '&.gallery': {
          maxWidth: 'unset',
          paddingTop: '0',
          paddingBottom: '0',
        },
        '&.dates': {
          maxWidth: '100%',
        },
      },
    },
  },
  '.gallery': {
    '.gallery-button': {
      padding: '1rem !important',
      textAlign: 'center',
      backgroundColor: black,
      border: `1px solid ${primaryDark}`,
      '&:hover': {
        backgroundColor: secondaryDark,
      },
      '&:focus, &:active': {
        backgroundColor: black,
      },
      '.MuiButton-icon': {
        margin: '0 !important',
      },
      '&.next': {
        marginLeft: '-1rem',
      },
      '&.previous': {
        marginRight: '1rem',
      },
    },
  },
  '.icon': {
    '&.black': {
      fill: '#000000',
    },
  },
  '.link': {
    '&.social': {
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      width: '3rem',
      justifyContent: 'center',
      '.copy': {
        fontSize: '1rem',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        display: 'none',
        width: 'fit-content',
      },
      '&:hover, &:focus, &:active': {
        '.copy': {
          display: 'inline',
        },
        path: {
          stroke: primary,
        },
      },
    },
  },
});
