import React, { useMemo } from 'react';
import { Box, Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { Block } from '@gk-lab/kirbyreact/dist/esm/types/Block';
import { getBlockElement } from '../utils/sectionUtils';
import { SectionDefault } from '../types/Section';
import LogoAnimated from '../svg/LogoAnimated';
import LogoCopy from '../svg/LogoCopy';
import Tribute from '../svg/Tribute';
import ScrollDownButton from '../components/ScrollDownButton';

type Props = {
  data: SectionDefault;
  blocks?: Block[];
  sx?: SxProps<Theme>;
  showDetails?: boolean;
  scrollLink?: string;
  showAnimation?: boolean;
};

export default function HomeView(props: Props): React.JSX.Element {
  const { sx, data, showDetails, scrollLink, showAnimation } = props;
  const { blocks, previewCount, url, align } = data;

  const max = useMemo<number>(() => {
    if (showDetails || !previewCount || (previewCount ?? 0) === 0) {
      return Infinity;
    }
    return previewCount;
  }, [showDetails, previewCount]);

  return (
    <Stack
      className={`content welcome ${url.replace('/', '-')}`}
      flexDirection={'column'}
      sx={{
        width: '100%',
        py: {
          xs: '6rem',
          md: '6rem',
        },
        justifyContent: 'space-between',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop:
            (blocks ?? []).length > 0 ? { xs: '10vh', md: '15vh', xl: '20vh' } : { xs: '20vh', md: '15vh', xl: '20vh' },
          zIndex: 100,
          pointerEvents: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            maxWidth: { xs: '30vw', md: '15vw', xl: '200px' },
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'fit-content',
          }}
        >
          <LogoAnimated animate={showAnimation} />
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: { xs: '100%', md: '768px', xl: '1024px' },
            height: 'fit-content',
            maxHeight: showAnimation ? '1px' : 'unset',
            overflow: 'hidden',
            animation: showAnimation ? 'maskFromTop 1s ease 1.5s forwards' : 'none',
          }}
        >
          <LogoCopy />
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: { xs: '90%', md: '600px', xl: '800px' },
            marginTop: { xs: '-3vw', md: '-1.5rem', xl: '-2rem' },
            overflow: 'hidden',
            opacity: showAnimation ? 0 : 1,
            animation: showAnimation ? 'fadeIn 1s ease 1.75s forwards' : 'none',
          }}
        >
          <Tribute />
        </Box>
        {scrollLink && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0,
              animation: showAnimation ? 'fadeIn 1s ease 5s forwards' : 'none',
            }}
          >
            <ScrollDownButton scrollLink={scrollLink} />
          </Box>
        )}
      </Stack>

      <Stack
        sx={{
          position: 'relative',
          width: '100%',
          zIndex: 99,
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: align ?? 'center',
          opacity: 0,
          px: {
            xs: '1rem',
            md: '4rem',
            lg: '6rem',
          },
          animation: showAnimation ? 'fadeIn 0.8s ease 3.5s forwards' : 'none',
        }}
      >
        {blocks?.map((block, idx) => (idx < max ? getBlockElement(block, idx, 'section') : null))}
      </Stack>
    </Stack>
  );
}
