import React, { useMemo } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, Stack, Container, IconButton, Typography } from '@mui/material';
import { BlockHeader, KirbyProps } from '@gk-lab/kirbyreact';
import { black, headerHeight } from '../Theme';
import MenuIcon from '@material-ui/icons/Menu';
import BackButton from './BackButton';
import Logo from '../svg/Logo';
import { ArrowCircleLeftOutlined } from '@mui/icons-material';
import Dodecagon from '../svg/Dodecagon';
import SocialLink from './SocialLink';
import Socials from '../svg/Socials';
import CloseIcon from '@mui/icons-material/Close';

type SocialData = {
  company: string;
  url: string;
};

interface Props extends KirbyProps {
  open: boolean;
  showClose?: boolean;

  data?: BlockHeader;
  socials?: SocialData[];
  /**
   * Added to <AppBar />
   */
  sx?: SxProps<Theme>;
  /**
   * <Container maxWidth />
   */
  maxWidth: 'sm' | 'md' | 'lg' | 'xl' | false;
  /**
   * Added to <Container />
   */
  containerSx?: SxProps<Theme>;
  onMenuClick: () => void;
}

export default function AppHeader(props: Props) {
  const { open, showClose, socials } = props;

  const toolbarStyle: SxProps = {
    maxHeight: 'inherit',
    backgroundColor: black,
    padding: '0 !important',
  };

  const containerMaxWidth: 'sm' | 'md' | 'lg' | 'xl' | false = useMemo(() => {
    switch (props.maxWidth) {
      case 'sm':
      case 'md':
      case 'lg':
      case 'xl':
        return props.maxWidth;
      default:
        return false;
    }
  }, [props.maxWidth]);

  return (
    <AppBar className={'app-bar'} position="fixed" elevation={props.asOverlay ? 0 : 3} sx={{ ...props.sx }}>
      <Container maxWidth={containerMaxWidth} sx={{ maxHeight: 'inherit', ...props.containerSx }}>
        <Toolbar sx={toolbarStyle} variant={props.asOverlay ? 'dense' : 'regular'}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ width: '100%', maxHeight: 'inherit' }}
          >
            <Box
              className={'left-content'}
              sx={{
                marginTop: '-15rem',
                animation: `slideIn 0.8s ease 2s normal forwards`,
              }}
            >
              <BackButton
                fallbackUrl={'/'}
                forceUrl={!showClose ? '#welcome' : undefined}
                sx={{
                  display: 'flex',
                  alignSelf: 'flex-start',
                  maxHeight: 'inherit',
                  width: headerHeight,
                  height: headerHeight,
                  marginLeft: { xs: '-0.5rem', md: '1rem' },
                  transform: {
                    xs: 'scale(0.75) rotate(-7deg)',
                    md: 'scale(1) rotate(-7deg)',
                  },
                }}
              >
                <Logo />
              </BackButton>
            </Box>
            <Box className={'center-content'}>
              {showClose && (
                <BackButton fallbackUrl={'/'}>
                  <ArrowCircleLeftOutlined />
                  Zurück
                </BackButton>
              )}
            </Box>
            <Stack
              className={'right-content'}
              sx={{
                flexDirection: 'row',
                gap: { xs: 0, md: 4 },
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginRight: { xs: '-2rem', md: '0' },
              }}
            >
              <Stack
                className={'socials'}
                sx={{
                  flexDirection: 'row',
                  gap: 2,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                {socials?.map((soc, idx) => {
                  const dl = 4 - (idx / (socials?.length ?? 1)) * 0.75;
                  return (
                    <SocialLink
                      id={soc.company}
                      to={soc.url}
                      style={{
                        visibility: showClose ? 'hidden' : 'visible',
                        maxWidth: showClose ? 0 : 'unset',
                        opacity: 0,
                        animation: `fadeIn 0.8s ease ${dl}s normal forwards`,
                      }}
                    >
                      <Socials company={soc.company} />
                      <Typography className={'copy'} component={'span'}>
                        {soc.company}
                      </Typography>
                    </SocialLink>
                  );
                })}
              </Stack>
              <Box
                className={'menu-opener'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '-15rem',
                  animation: `slideIn 0.8s ease 2.5s normal forwards`,
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      transformOrigin: 'center',
                      transition: open ? 'all 0.3s ease-out' : 'all 0.3s ease-in',
                      opacity: open ? 0 : 1,
                      transform: open ? 'scale(3)' : { xs: 'scale(0.75)', md: 'scale(1)' },
                      pointerEvents: open ? 'none' : 'auto',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transformOrigin: 'center',
                        animation: open ? 'none' : `rotate 10s linear infinite`,
                      }}
                    >
                      <Dodecagon />
                    </Box>
                  </Box>
                  <IconButton
                    onClick={props.onMenuClick}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      margin: 'auto',
                      pointerEvents: 'auto',
                      cursor: 'pointer',
                    }}
                  >
                    {!open ? <MenuIcon sx={{ transform: 'rotate(7deg)' }} className={'icon black'} /> : <CloseIcon />}
                  </IconButton>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Toolbar>
        {props.children}
      </Container>
    </AppBar>
  );
}
