import React, { useEffect, useMemo, useState } from 'react';

export default function Loader() {
  const [styleLeft, setStyleLeft] = useState<any>({ transform: 'scale(1,0)' });
  const [styleCenter, setStyleCenter] = useState<any>({
    transform: 'scale(1,0)',
  });
  const [styleRight, setStyleRight] = useState<any>({
    transform: 'scale(1,0)',
  });

  useEffect(() => {
    const dr1 = 4 + Math.random() * 3;
    const dr2 = 4 + Math.random() * 3;
    const dr3 = 4 + Math.random() * 3;

    const dl1 = 0.2 + Math.random() * 0.5;
    const dl2 = 0.2 + Math.random() * 0.5;
    const dl3 = 0.2 + Math.random() * 0.5;

    setStyleLeft({
      animation: `scale ${dr1}s linear ${dl1}s infinite`,
      transformOrigin: '0 220px',
    });

    setStyleCenter({
      animation: `scale ${dr2}s linear ${dl2}s infinite`,
      transformOrigin: '0 220px',
    });

    setStyleRight({
      animation: `scale ${dr3}s linear ${dl3}s infinite`,
      transformOrigin: '0 220px',
    });
  }, []);

  /*  const circle = useMemo(() => {
    return {
      animation: `spin 20s linear infinite`,
      transformOrigin: "160px 160px",
    }
  },[])*/

  const crown = useMemo(() => {
    return {
      animation: `blink 2s linear infinite`,
    };
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      width="160px"
      height="160px"
      viewBox="0 0 320 320"
    >
      {/*
      <g id="circle" style={circle}>
        <path fill="#111111" stroke="none" d="
M 223.95 56.1
Q 223.15 55.35 222 55.05 184.05 44.85 161.5 38.8 160.6 38.55 159.55 38.8 119.2 49.65 99 55.05 97.9 55.35 97.1 56.15 74.95 78.3 52.85 100.45 52.05 101.25 51.75 102.3 42.65 136.5 35.65 162.8 35.35 163.95 35.65 165.2 42.6720703125 191.498046875 50.35 220.45 51.05625 223.039453125 51.75 225.65 52.05 226.75 52.85 227.55 77.45 252.2 97.05 271.85 97.95 272.65 99.1 272.95 119.9939453125 278.605859375 136.1 282.95 149.3404296875 286.5171875 159.35 289.2 160.55 289.5 161.6 289.2 172.708203125 286.2419921875 184.95 282.95 202.330078125 278.28515625 222 272.95 223 272.65 223.9 271.85 243.55 252.25 268.2 227.5 269 226.7 269.2 225.85 269.715234375 223.927734375 270.6 220.45 272.0259765625 215.15390625 274.35 206.25
L 285.4 165.05
Q 285.65 164 285.4 163 279.95 142.8 269.2 102.3 269 101.35 268.2 100.55 248.55 80.8 223.95 56.1 Z"/>
      </g>
*/}

      <g id="crown_white" style={crown}>
        <path
          fill="#EBD593"
          stroke="#EBD593"
          d="
M 197.15 105.95
Q 196.8 106.1 196.2 106.05 195.65 106 195.25 105.8 190 102.75 176.55 94.65
L 160.15 84.85
Q 133.5 100.8 125.15 105.8 124.75 106 124.25 106.05 123.75 106.15 123.35 106 122.1 105.55 99.75 97.05
L 88.25 92.6 88.25 135 232.05 135 232.05 92.7 197.15 105.95 Z"
        />
      </g>

      <g id="right_white">
        <path
          fill="none"
          stroke="#EBD593"
          d="
M 232.05 142.25
L 189 142.25 189 242.3 189.4 242.3 231.1 228.45
Q 231.75 228.25 231.95 227.85 232.15 227.55 232.15 226.85
L 232.05 142.25 Z"
        />
      </g>

      <g id="center_white">
        <path
          fill="none"
          stroke="#EBD593"
          d="
M 181.8 242.3
L 181.8 142.25 138.7 142.25 138.7 242.3 181.8 242.3 Z"
        />
      </g>

      <g id="left_white">
        <path
          fill="none"
          stroke="#EBD593"
          d="
M 131.4 142.25
L 88.2 142.25 88.2 228.1 131.4 242.35 131.4 142.25 Z"
        />
      </g>

      {/*      <g id="crown_prim">
        <path fill="#EBD593" stroke="none" d="
M 196.2 106.05
Q 195.65 106 195.25 105.8 190 102.75 176.55 94.65
L 160.15 84.85
Q 133.5 100.8 125.15 105.8 124.75 106 124.25 106.05 123.75 106.15 123.35 106 122.1 105.55 99.75 97.05
L 88.25 92.6 88.25 135 232.05 135 232.05 92.7 197.15 105.95
Q 196.8 106.1 196.2 106.05 Z"/>
      </g>*/}

      <g id="right-prim-bot">
        <path
          fill="#EBD593"
          stroke="none"
          d="
M 232.15 226.85
L 232.15 217.95 189 217.95 189 242.3 189.4 242.3 231.1 228.45
Q 231.75 228.25 231.95 227.85 232.15 227.55 232.15 226.85 Z"
        />
      </g>

      <g id="center-prim-bot">
        <path
          fill="#EBD593"
          stroke="none"
          d="
M 138.7 217.95
L 138.7 242.3 181.8 242.3 181.8 217.95 138.7 217.95 Z"
        />
      </g>

      <g id="left-prim-bot">
        <path
          fill="#EBD593"
          stroke="none"
          d="
M 131.4 242.35
L 131.4 217.95 88.2 217.95 88.2 228.1 131.4 242.35 Z"
        />
      </g>

      <g id="right-prim" style={styleRight}>
        <path
          fill="#EBD593"
          stroke="none"
          d="
M 232.05 142.25
L 189 142.25 189 217.95 232.15 217.95 232.05 142.25 Z"
        />
      </g>

      <g id="center_prim" style={styleCenter}>
        <path
          fill="#EBD593"
          stroke="none"
          d="
M 138.7 217.95
L 181.8 217.95 181.8 142.25 138.7 142.25 138.7 217.95 Z"
        />
      </g>

      <g id="left-prim" style={styleLeft}>
        <path
          fill="#EBD593"
          stroke="none"
          d="
M 131.4 142.25
L 88.2 142.25 88.2 217.95 131.4 217.95 131.4 142.25 Z"
        />
      </g>
    </svg>
  );
}
