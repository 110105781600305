import React from 'react';
import { IKirbyImage } from '../../../.yalc/@gk-lab/kirbyreact';
import GalleryThumb from '../GalleryThumb/GalleryThumb';
import { BlockGallery } from '../../types/Block';
import { Stack } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

interface Props {
  data?: BlockGallery;
  sx?: SxProps<Theme>;
}

export default function Gallery(props: Props): React.JSX.Element {
  const { data, sx } = props;
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        ...sx,
      }}
      className={'gallery'}
    >
      {data?.images?.map((image: IKirbyImage) => {
        return <GalleryThumb key={image.url} name={`${image.name}`} url={image.thumb ?? image.url} />;
      })}
    </Stack>
  );
}
