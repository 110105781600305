import { Breakpoints, CSSObject } from '@mui/material';
import { black, primary } from '../Theme';

export const navigation = (breakpoints: Breakpoints): CSSObject => ({
  '.navigation': {
    minWidth: '280px',
    [breakpoints.up('sm')]: {},
    [breakpoints.up('md')]: {
      minWidth: '440px',
    },
    [breakpoints.up('lg')]: {},
    [breakpoints.up('xl')]: {},
    '.header': {
      color: `${primary} !important`,
      fontSize: '1rem',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    a: {
      pointerEvents: 'auto',
      width: 'fit-content',
      fontSize: '2rem',
      '&.main-navigation': {
        '&.main': {
          padding: '.75rem 1.25rem !important',
          color: `${primary} !important`,
          '&.active, &.focus': {
            background: primary,
            color: `${black} !important`,
          },
        },
        '&.footer': {
          padding: '.25rem 0.5rem !important',
          color: `${primary} !important`,
          fontSize: '1rem',
          '&.active, &.focus': {
            background: primary,
            color: `${black} !important`,
          },
        },
      },
    },
  },
});
