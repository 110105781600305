import React, { useMemo } from 'react';
import { Link, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
  photographer?: string;
  link?: string;
  caption?: string;
};

export default function ImageFooter(props: Props): React.JSX.Element {
  const link = useMemo<React.JSX.Element | string | null>(() => {
    if (props.link && props.photographer) {
      return (
        <Link href={props.link} target={'_blank'}>
          © {props.photographer}
        </Link>
      );
    } else if (props.photographer) {
      return `© ${props.photographer}`;
    }
    return null;
  }, [props.link, props.photographer]);

  return (
    <Typography component={'p'} sx={{ ...props.sx }}>
      {link && <span style={{ display: 'block' }}>{link}</span>}
      {props.caption && <span>{props.caption}</span>}
    </Typography>
  );
}
