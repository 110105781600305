import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Overlay from '../svg/Overlay';

type Props = {
  loaded: boolean;
};

export default function AppBlender(props: Props): React.JSX.Element | null {
  const { loaded } = props;
  const [invisible, setInvisible] = useState(false);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => setInvisible(true), 5000);
    }
  }, [loaded]);

  if (invisible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        overflow: 'hidden',
      }}
    >
      {loaded && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: '#EBD593',
            zIndex: 99,
            animation: 'blendOut 1s ease forwards',
          }}
        />
      )}
      <Box
        sx={{
          zIndex: 100,
        }}
      >
        <Overlay open={loaded} />
      </Box>
    </Box>
  );
}
