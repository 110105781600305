import React from 'react';
import { Button, Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { Block } from '@gk-lab/kirbyreact/dist/esm/types/Block';
import { getBlockElement } from '../utils/sectionUtils';
import { Link as RouterLink } from 'react-router-dom';
import { SectionDefault } from '../types/Section';

type Props = {
  data: SectionDefault;
  blocks?: Block[];
  sx?: SxProps<Theme>;
  showDetails?: boolean;
};

export default function DefaultView(props: Props): React.JSX.Element {
  const { sx, data } = props;
  const { url, filtered, cta } = data;

  return (
    <Stack
      className={`content default ${url.replace('/', '-')}`}
      sx={{
        flexDirection: 'column',
        alignItems: data?.align ?? 'center',
        py: {
          xs: '6rem',
          md: '6rem',
        },
        px: {
          xs: '1rem',
          md: '4rem',
          lg: '6rem',
        },
        ...sx,
      }}
    >
      {data?.blocks?.map((block, idx) => getBlockElement(block, idx, 'section'))}
      {filtered && (data?.blocks ?? []).length > 0 && (
        <Button component={RouterLink} to={`/${url}`} sx={{}}>
          {cta ?? 'Mehr?'}
        </Button>
      )}
    </Stack>
  );
}
