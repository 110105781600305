import React from 'react';
import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { SectionDefault, SectionGallery } from '../types/Section';
import { Route, Routes } from 'react-router-dom';
import { GalleryImage } from '../components/GalleryImage/GalleryImage';
import Gallery from '../components/Gallery/Gallery';
import { CustomBlockType } from '../types/Block';
import { usePageData } from '../hooks/usePageData';
import { getPath } from '../config';

interface Props {
  data?: SectionGallery;
  sx?: SxProps<Theme>;
}

export default function GalleryView(props: Props): React.JSX.Element {
  const { data } = props;

  const details = usePageData<SectionDefault>(`${getPath()}${data?.url}`, [data?.url], Boolean(data?.url));

  return (
    <Box
      className={`content gallery ${data?.url.replace('/', '-')}`}
      sx={{
        backgroundColor: 'black',
        minHeight: '100vh',
      }}
    >
      <Routes>
        <Route path={`/:id`} element={<GalleryImage path={details.data?.url} images={details.data?.images} />} />
        <Route
          path={`/`}
          element={
            <Gallery
              data={{
                type: CustomBlockType.GALLERY,
                images: details.data?.images ?? [],
                rowheight: 240,
                columnsXs: 2,
                columnsMd: 3,
                columnsLg: 4,
              }}
            />
          }
        />
      </Routes>
    </Box>
  );
}
