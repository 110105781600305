import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IKirbyImage } from '@gk-lab/kirbyreact';
import { Box } from '@mui/material';

export default function GalleryThumb(props: IKirbyImage): React.JSX.Element {
  const { url, name } = props;
  return (
    <Box
      className={'header'}
      component={RouterLink}
      sx={{
        display: 'flex',
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        width: {
          xs: '50%',
          md: '33%',
          lg: '25%',
          xl: '12.5%',
        },
        cursor: 'pointer',
        aspectRatio: '1 / 1',
      }}
      to={name}
    />
  );
}
