import React, { useEffect, useMemo, useState } from 'react';

export default function Overlay(props: { open: boolean }) {
  const { open } = props;

  const [dim, setDim] = useState(0);
  //const [offsetX, setOffsetX] = useState(0);
  //const [offsetY, setOffsetY] = useState(0);

  function onResize() {
    const w = window.innerWidth;
    const h = window.innerHeight;

    if (w > h) {
      setDim(w);
      //setOffsetX(0);
      //setOffsetY((-(w - h) / 2) * (640 / w));
    } else {
      setDim(h);
      //setOffsetX((-(h - w) / 2) * (640 / h));
      //setOffsetY(0);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const outerStyle = useMemo(() => {
    if (open) {
      return {
        opacity: 1,
        animation: `blowOut 1s ease-in 0s normal forwards`,
        transformOrigin: `320px 320px`,
      };
    }

    return {
      opacity: 1,
      animation: `blowing 4s linear 0s infinite`,
      transformOrigin: `320px 320px`,
    };
  }, [open /*, offsetX, offsetY*/]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      width={`${dim}px`}
      height={`${dim}px`}
      viewBox={`0 0 640 640`}
      preserveAspectRatio="xMidYMid slice"
    >
      <g id="black" style={outerStyle}>
        <path
          fill="#000000"
          stroke="none"
          d="
M 640 0
L 0 0 0 640 640 640 640 0
M 357.45 253.9
Q 358.1 254.15 358.6 254.55 373.55 269.55 385.5 281.55 386 282.05 386.05 282.6 392.6 307.2 395.9 319.5 396.05 319.75 396.05 320.15 396.05 320.4 395.9 320.75
L 389.2 345.75
Q 387.8 351.2 387 354.4 386.4 356.5 386.05 357.65 386 358.2 385.5 358.7 370.45 373.75 358.6 385.6 358 386.1 357.45 386.3 345.45 389.5 334.9 392.4 327.45 394.35 320.7 396.2 320.45 396.25 320.1 396.25 319.7 396.25 319.4 396.2 313.25 394.55 305.25 392.4 295.45 389.75 282.75 386.3 282.05 386.1 281.5 385.6 269.6 373.65 254.65 358.7 254.2 358.2 254 357.55 253.6 356 253.2 354.4 248.5 336.85 244.2 320.85 244.1 320.5 244.1 320.1 244.1 319.75 244.2 319.35 248.5 303.4 254 282.6 254.2 281.95 254.65 281.45 268.15 268 281.6 254.55 282 254.15 282.75 253.9 294.95 250.65 319.45 244.05 319.8 244 320.1 244 320.35 244 320.7 244.05 334.4 247.7 357.45 253.9 Z"
        />
      </g>

      {/*      <g id="inner" style={innerStyle}>
        <path fill="#111111" stroke="none" d="
M 358.6 254.55
Q 358.1 254.15 357.45 253.9 334.4 247.7 320.7 244.05 320.35 244 320.1 244 319.8 244 319.45 244.05 294.95 250.65 282.75 253.9 282 254.15 281.6 254.55 268.15 268 254.65 281.45 254.2 281.95 254 282.6 248.5 303.4 244.2 319.35 244.1 319.75 244.1 320.1 244.1 320.5 244.2 320.85 248.5 336.85 253.2 354.4 253.6 356 254 357.55 254.2 358.2 254.65 358.7 269.6 373.65 281.5 385.6 282.05 386.1 282.75 386.3 295.45 389.75 305.25 392.4 313.25 394.55 319.4 396.2 319.7 396.25 320.1 396.25 320.45 396.25 320.7 396.2 327.45 394.35 334.9 392.4 345.45 389.5 357.45 386.3 358 386.1 358.6 385.6 370.45 373.75 385.5 358.7 386 358.2 386.05 357.65 386.4 356.5 387 354.4 387.8 351.2 389.2 345.75
L 395.9 320.75
Q 396.05 320.4 396.05 320.15 396.05 319.75 395.9 319.5 392.6 307.2 386.05 282.6 386 282.05 385.5 281.55 373.55 269.55 358.6 254.55 Z"/>
</g>*/}
    </svg>
  );
}
