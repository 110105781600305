import axios from 'axios';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

async function getData<T>(path: string): Promise<T> {
  const { data } = await axios.get(path);
  return {
    ...data,
  };
}

export function usePageData<T>(url: string = '', key: QueryKey, enable: boolean = true): UseQueryResult<T, unknown> {
  return useQuery({
    queryKey: key,
    queryFn: () => getData<T>(url),
    enabled: enable,
  });
}
