import {
  Block,
  BlockType,
  getKirbyBlockElement,
  KirbyBlockGroup,
  KirbySlide,
  KirbySlideshow,
} from '@gk-lab/kirbyreact';
import React, { ReactNode } from 'react';
import { Container, Stack } from '@mui/material';
import { CustomBlock, CustomBlockType } from '../types/Block';
import { slideshowButtonSx } from '../Theme';
import CookieReset from '../components/CookieReset';
import CustomFooter from '../components/CustomFooter';
import Preview from '../components/Preview';
import Post from '../components/post/Post';
import Event from '../components/event/Event';
import Gallery from '../components/Gallery/Gallery';
import Video from '../components/video/Video';

export function wrapWithCustomRow(
  element: ReactNode | null,
  type?: BlockType | '',
  idx?: number
): React.JSX.Element | ReactNode | null {
  const key = idx || type ? `${type ?? 'block'}-${idx ? idx.toString() : '0'}` : '';

  switch (type) {
    case BlockType.PAGECONTAINER:
    case BlockType.FRAME:
    case BlockType.PAGE:
      return (
        <Container
          maxWidth={false}
          sx={{
            maxWidth: '1920px',
          }}
          key={key}
        >
          <Stack direction={'column'} className={'section-stack'}>
            {element}
          </Stack>
        </Container>
      );
    case BlockType.NAVIGATION:
      return (
        <Container
          maxWidth={false}
          sx={{
            marginTop: '6rem',
            marginBottom: '4rem',
            display: 'flex',
            maxWidth: '1920px',
            height: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            overflowX: 'hidden',
            maxHeight: 'calc(100% - 6rem)',
          }}
          key={key}
        >
          {element}
        </Container>
      );

    default:
      return element;
  }
}

export function getBlockElement(b: Block | CustomBlock, idx: number, scope?: string): React.JSX.Element | null {
  if (b) {
    switch (b.type) {
      case CustomBlockType.GALLERY:
        return <Gallery data={b} key={`gallery-${idx}`} />;
      case CustomBlockType.PREVIEW:
        return (
          <Preview key={`preview-${idx.toString()}`} data={b} scope={scope}>
            {b.blocks &&
              b.blocks.map((block, idx) => {
                return getBlockElement(block, idx, 'preview');
              })}
          </Preview>
        );
      case CustomBlockType.POST:
        return <Post key={`post-${idx.toString()}`} data={b} scope={scope} />;
      case CustomBlockType.VIDEO:
        return <Video key={`post-${idx.toString()}`} data={b} scope={scope} />;
      case CustomBlockType.EVENT:
        return <Event key={`event-${idx.toString()}`} data={b} scope={scope} />;
      case BlockType.SLIDESHOW2:
        return (
          <KirbySlideshow
            data={b}
            key={`slideshow-${idx.toString()}`}
            carouselProps={{
              buttonSx: slideshowButtonSx,
              indicatorPos: 'right',
              navigationPos: 'spread',
            }}
            scope={scope}
          >
            {b.slides &&
              b.slides.map((slide, idx) => {
                return getBlockElement(slide, idx);
              })}
          </KirbySlideshow>
        );
      case BlockType.SLIDE:
        return (
          <KirbySlide key={`slide-${idx.toString()}`} data={b} scope={scope}>
            {b.blocks &&
              b.blocks.map((block, idx) => {
                return getBlockElement(block, idx, 'slide');
              })}
          </KirbySlide>
        );
      case BlockType.BLOCKGROUP:
        const elements = b.items?.map((bc: Block) => {
          return getBlockElement(bc, 0, scope);
        });
        return (
          <KirbyBlockGroup key={`group-${idx.toString()}`} data={b} idx={idx + 1} elements={elements} scope={scope} />
        );
      case CustomBlockType.COOKIERESET:
        return <CookieReset key={`cookiereset-${idx.toString()}`} data={b} scope={scope} />;
      case BlockType.FOOTER:
        return <CustomFooter key={`footer-${idx.toString()}`} getBlockElement={getBlockElement} />;
      default:
        return getKirbyBlockElement(b, idx, scope);
    }
  }
  return null;
}
