import React, { useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { IKirbySection, KirbyProps } from '@gk-lab/kirbyreact';
import { useInView } from 'react-intersection-observer';
import { SectionDefault, SectionEvents, SectionGallery } from '../types/Section';
import EventsView from '../views/EventsView';
import GalleryView from '../views/GalleryView';
import FooterView from '../views/FooterView';
import DefaultView from '../views/DefaultView';
import { usePageData } from '../hooks/usePageData';
import { getPath } from '../config';
import Loader from '../svg/Loader';

interface Props extends KirbyProps {
  matchLocation?: boolean;
  sectionData: IKirbySection;
  onVisible?(sectionId: string, value: boolean): void;
  className?: string;
}

export default function DefaultPage(props: Props) {
  const { sectionData, matchLocation, onVisible, className, sx } = props;

  const { ref, inView: isVisible } = useInView({
    threshold: 0.3,
  });

  const details = usePageData<SectionDefault>(`${getPath()}${sectionData.url}`, [sectionData.url], matchLocation);

  const viewData: IKirbySection | undefined = useMemo<IKirbySection | undefined>(() => {
    if (!matchLocation) {
      return sectionData;
    } else {
      if (details.isSuccess) {
        return details.data;
      }
    }
    return undefined;
  }, [matchLocation, details, sectionData]);

  useEffect(() => {
    if (onVisible) onVisible(sectionData.url, isVisible);
    if (isVisible) {
      document.title = `MUSIC MONKS - ${sectionData.title.toUpperCase()}`;
    }
  }, [sectionData.url, sectionData.title, isVisible, onVisible]);

  function getSection(data: IKirbySection, match?: boolean) {
    switch (data.id) {
      case 'events':
        return <EventsView showDetails={match} data={data as SectionEvents} />;
      case 'gallery':
        return <GalleryView data={data as SectionGallery} />;
      case 'footer':
        return <FooterView data={data as SectionDefault} />;
      default:
        return <DefaultView showDetails={match} data={data as SectionDefault} />;
    }
  }

  return (
    <Box
      component="section"
      ref={ref}
      id={`${sectionData.url}-page`}
      className={['page', `${sectionData.url.replace('/', '-')}`, className].filter((cn) => Boolean(cn)).join(' ')}
      sx={{
        position: 'relative',
        width: '100%',
        backgroundColor: matchLocation ? "#111111" : "transparent",
        ...sx,
      }}
    >
      {matchLocation && details.isLoading && (
        <Stack
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
          }}
        >
          <Loader />
        </Stack>
      )}
      {viewData && getSection(viewData, matchLocation)}
    </Box>
  );
}
