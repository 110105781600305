import React from 'react';
import { Box, Dialog, Slide, Stack, Typography } from '@mui/material';
import { Block, BlockType } from '@gk-lab/kirbyreact';
import MainNavigationLink from './MainNavigationLink';
import { TransitionProps } from '@mui/material/transitions';
import { SocialLink } from '../../types/Block';
import { defaultPadding } from '../../Theme';
import SocialLinkButton from '../SocialLinkButton';

interface Props {
  open: boolean;
  handleClose(): void;
  handleClick(): void;
  mainItems?: Block[];
  metaItems?: Block[];
  facebook?: string;
  instagram?: string;
  socials?: SocialLink[];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function MainNavigation(props: Props): React.JSX.Element {
  const { open, handleClose, handleClick, mainItems, metaItems } = props;

  function onClick() {
    setTimeout(() => {
      handleClick();
    }, 250);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={'navigation'}
      scroll={'paper'}
      style={{
        height: '100%',
        //minWidth: "280px",
        background: 'transparent',
      }}
    >
      <Stack
        className={'items'}
        direction="column"
        gap={8}
        sx={{
          background: 'transparent',
          width: '100%',
          minHeight: 'fit-content',
          height: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: { xs: '7rem', md: '9rem' },
          paddingBottom: { xs: '2rem', lg: '12rem' },
          paddingLeft: '2rem',
          paddingRight: '2rem',
        }}
      >
        <Stack
          direction="column"
          className={'main-items'}
          sx={{
            overflow: 'hidden',
            width: '100%',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Typography className={'header'}>music-monks.com</Typography>
          {mainItems?.map((item, idx) => {
            if (item.type === BlockType.LINK) {
              const url = item?.to && item?.asAnchor ? `#${item?.to}` : item?.to ?? '/';
              return (
                <MainNavigationLink
                  url={`/${url}`}
                  onClick={onClick}
                  copy={item.text}
                  scope={'main'}
                  sx={{
                    width: '100%',
                  }}
                  key={`${idx.toString()}`}
                />
              );
            }
            return <Box key={`${idx.toString()}`} />;
          })}
        </Stack>
        <Stack
          direction="column"
          className={'footer-items'}
          sx={{
            overflow: 'hidden',
            width: '100%',
            alignItems: { xs: 'center', lg: 'flex-end' },
            gap: 2,
          }}
        >
          {metaItems?.map((item, idx) => {
            if (item.type === BlockType.LINK) {
              const url = item?.to && item?.asAnchor ? `#${item?.to}` : item?.to ?? '/';
              return (
                <MainNavigationLink
                  url={url}
                  onClick={onClick}
                  copy={item.text}
                  sx={{
                    width: '100%',
                  }}
                  scope={'footer'}
                  key={`${idx.toString()}`}
                />
              );
            }
            return <Box key={`${idx.toString()}`} />;
          })}
        </Stack>
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        columnGap={3}
        sx={{
          position: 'absolute',
          bottom: '1rem',
          right: defaultPadding,
          left: defaultPadding,
          maxWidth: '1920px',
          margin: '0 auto',
          justifyContent: 'space-between',
        }}
      >
        <Stack gap={3} direction="row" key={`socials`}>
          {props.socials &&
            props.socials?.map((sl, idx) => {
              return <SocialLinkButton key={`sl-${idx.toString()}`} social={sl} />;
            })}
        </Stack>
      </Stack>
    </Dialog>
  );
}
