import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { IKirbySection } from '@gk-lab/kirbyreact';
import DefaultPage from '../pages/DefaultPage';
import { SectionDefault } from '../types/Section';
import { Route, Routes, useLocation } from 'react-router-dom';
import useMuiBreakpoint from '../hooks/useMuiBreakpoint';
import { ccn } from '../utils/utils';
import HomePage from '../pages/HomePage';
import BackgroundImage from '../components/background/BackgroundImage';
import Backgrounds from '../components/background/Backgrounds';

type Props = {
  sections: IKirbySection[];
  sx?: SxProps<Theme>;
};

export default function Main(props: Props): React.JSX.Element {
  const { sections: kirbySections, sx } = props;
  const location = useLocation();
  const [visibleSection, setVisibleSection] = useState('');
  const [showHomeAnimation, setShowHomeAnimation] = useState<boolean | undefined>(undefined);
  const breakpoint = useMuiBreakpoint();
  const [scrollBehavior, setScrollBehavior] = useState<'auto' | 'smooth'>('auto');

  useEffect(() => {
    if (showHomeAnimation === undefined) {
      setShowHomeAnimation(location.pathname === '/' && location.hash === '#welcome');
    } else {
      setShowHomeAnimation(false);
    }
  }, [location.hash, location.pathname, showHomeAnimation]);

  const handleVisibleSection = useCallback(
    (sectionId: string, value: boolean) => {
      const section = kirbySections.find((s) => s.url === sectionId);

      // Deeplink for unlisted pages
      if (section && section.status === 'unlisted') return undefined;

      if (value && location.pathname === '/') {
        window.location.hash = `#${sectionId}`;
        setVisibleSection(sectionId);
      }
    },
    [kirbySections, location.pathname]
  );

  const getSection = useCallback(
    (sectionData: IKirbySection, match: boolean) => {
      if (sectionData.url === 'welcome') {
        return (
          <HomePage
            key={sectionData.url}
            sectionData={sectionData}
            scrollLink={`/#${kirbySections[1]?.url}`}
            onVisible={handleVisibleSection}
            matchLocation={match}
            animate={showHomeAnimation}
            className={ccn([sectionData.url, match ? 'detail' : 'welcome'])}
            sx={{
              minHeight: sectionData.id !== 'footer' ? '80vh' : 'fit-content',
              justifyContent: 'space-between',
            }}
          />
        );
      } else {
        return (
          <DefaultPage
            key={sectionData.url}
            sectionData={sectionData}
            onVisible={handleVisibleSection}
            matchLocation={match}
            className={ccn([sectionData.url, match ? 'detail' : 'welcome'])}
            sx={{
              minHeight: sectionData.id !== 'footer' ? '80vh' : 'fit-content',
              justifyContent: 'space-between',
            }}
          />
        );
      }
    },
    [kirbySections, handleVisibleSection, showHomeAnimation]
  );

  /**
   * Home Sections
   */
  const mainSections = useMemo(() => {
    return kirbySections
      .filter((s) => s.status === 'listed')
      .map((kirbySection) => {
        const sectionPathname = `/${kirbySection.url}`;
        if (sectionPathname.startsWith(location?.pathname ?? '/')) {
          const match = (location?.pathname ?? '/') !== '/' && sectionPathname.startsWith(location?.pathname ?? '/');

          return getSection(kirbySection, match);
        }
        return null;
      })
      .filter((s) => Boolean(s));
  }, [getSection, kirbySections, location.pathname]);

  /**
   * Sections available via deeplink
   */
  const unlistedRoutes = useMemo(() => {
    return kirbySections
      .filter((s) => s.status === 'unlisted')
      .map((kirbySection) => {
        const sectionPathname = `/${kirbySection.url}`;
        return <Route path={`${sectionPathname}/*`} element={getSection(kirbySection, true)} />;
      });
  }, [getSection, kirbySections]);

  const activeSectionId = useMemo(() => {
    let sectionId = '';

    if (location.pathname === '/') {
      if (location.hash !== '') {
        sectionId = location.hash.replace('#', '');
      }
      if (!Boolean(sectionId)) sectionId = 'welcome';
    } else {
      sectionId = location.pathname.split('/')[1];
      return undefined;
    }

    // Check section
    const section = kirbySections.find((s) => s.url === sectionId);

    // Deeplink for unlisted pages
    if (section && section.status === 'unlisted') return undefined;

    return sectionId;
  }, [kirbySections, location]);

  /**
   * Scroll To Top on subpages
   */
  useEffect(() => {
    if (!activeSectionId) {
      window.scrollTo({ top: 0 });
      setScrollBehavior('auto');
    } else if (activeSectionId !== visibleSection) {
      const divId = !activeSectionId || activeSectionId === '' ? 'welcome-page' : `${activeSectionId}-page`;
      const el: HTMLElement | null = document.getElementById(divId);
      el?.scrollIntoView({ behavior: scrollBehavior });
      setScrollBehavior('smooth');
    }

    if (showHomeAnimation) {
      setShowHomeAnimation(!(activeSectionId !== 'welcome'));
    }
  }, [activeSectionId, visibleSection, scrollBehavior, showHomeAnimation]);

  /**
   * Backgrounds
   */
  const backgrounds: ComponentProps<typeof BackgroundImage>[] = useMemo(() => {
    return kirbySections
      .filter((s) => s.status === 'listed')
      .map((kirbySection) => {
        if (kirbySection.id === 'footer') {
          return {
            id: kirbySection.url,
            background: undefined,
            position: undefined,
          };
        } else {
          const sd: SectionDefault = kirbySection as SectionDefault;
          if (sd.background) {
            return {
              id: kirbySection.url,
              background: sd.background,
              position: sd.position,
            };
          }
        }
        return {
          id: kirbySection.url,
        };
      })
      .filter((b) => Boolean(b));
  }, [kirbySections]);

  /**
   * Titles
   */
  /*  const titles: ComponentProps<typeof PageTitle>[] = useMemo(() => {
    return kirbySections
      .filter((s) => s.status === 'listed')
      .map((kirbySection) => {
        return {
          id: kirbySection.url,
          title: kirbySection.title ?? '',
        };
      });
  }, [kirbySections]);*/

  return (
    <Stack flexDirection={'column'} sx={{ position: 'relative', ...sx }}>
      <Backgrounds mode={'fade'} backgrounds={backgrounds} activeSection={activeSectionId} breakpoint={breakpoint} />
      {/*<PageTitles titles={titles} activeSection={activeSectionId} breakpoint={breakpoint} />*/}
      {mainSections}
      <Routes>{unlistedRoutes}</Routes>
    </Stack>
  );
}
