import React from 'react';
import { Box, Container, Stack } from '@mui/material';
import { Block, useAppState } from '@gk-lab/kirbyreact';
import { footerBackground } from '../Theme';
import { SxProps, Theme } from '@mui/system';
import { CustomScope } from '../types/Scope';
import { useLocation } from 'react-router-dom';

interface Props {
  getBlockElement(b: Block, idx: number, scope?: string): React.JSX.Element | null;
  sx?: SxProps<Theme>;
}

export default function CustomFooter(props: Props) {
  const { footer } = useAppState();

  const { pathname } = useLocation();

  const { left, right } = footer;

  if (pathname !== '/') {
    return null;
  }

  return (
    <Box
      component={'footer'}
      sx={{
        width: '100%',
        zIndex: 1,
        ...props.sx,
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: footerBackground,
        }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          rowGap={{ xs: 4, lg: 0 }}
          sx={{
            width: '100%',
            padding: { xs: '0 1rem' },
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              width: { xs: '100%', lg: `${100 / 2}%` },
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
            }}
          >
            {left?.map((b: Block, idx: number) => {
              return props.getBlockElement(b, idx, CustomScope.FOOTER);
            })}
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              width: { xs: '100%', lg: `${100 / 2}%` },
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            {right?.map((b: Block, idx: number) => {
              return props.getBlockElement(b, idx, CustomScope.FOOTER);
            })}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
