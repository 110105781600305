import { createTheme, darken, lighten } from '@mui/material';
import { h1, h2, h3, h4, h5, h6 } from './styles/headlines';
import { p } from './styles/text';
import { defaultStyles } from './styles/uistyles';
import { content } from './styles/content';
import { backgrounds } from './styles/backgrounds';
import { navigation } from './styles/navigation';
import { footer } from './styles/footer';

const font = 'Oswald';
const fontFamily = [font, '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',');

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const headerHeight = '100px';
export const white = '#fff';
export const black = '#000';
export const dark = '#333';

export const primary = '#ecd693';
export const primaryDark = darken(primary, 0.2);

export const secondary = '#d5c8a2';
export const secondaryDark = darken(secondary, 0.2);
export const secondaryLight = lighten(secondary, 0.8);

export const accent = primary;
export const accentDark = primaryDark;

export const footerBackground = '#000000';

export const lightGrey = '#dddddd';

export const defaultPadding = { xs: '1rem', sm: '4.5rem' };

export const slideshowButtonSx = {
  background: accent,
  svg: { color: white },
  ':hover': {
    background: white,
    svg: { color: accent },
  },
  '&:active, &:focus, &.active, &.focus ': {
    background: accent,
    svg: { color: white },
  },
  display: 'none',
  [baseTheme.breakpoints.up('md')]: {
    display: 'inline-flex',
  },
};

export const theme = createTheme({
  breakpoints: baseTheme.breakpoints,
  spacing: 4,
  palette: {
    common: {
      black: '#000',
      white: white,
    },
    primary: {
      main: primary, // color-accent-primary-2
      dark: primaryDark, // color-accent-primary-2-dark
      light: '#d5d5d5', // color-accent-primary-3
    },
    secondary: {
      main: secondary, // color-accent-primary-1
      dark: secondaryDark, // color-accent-primary-2-dark
    },
    error: {
      main: primary,
    },
    warning: {
      main: '#FBC91D', // color-status-warning
      dark: '#AD1035', // color-secundary-1
      light: 'rgba(251,201,29,0.15)', // color-status-warning 15 %
    },
    info: {
      main: primary, // color-status-warning-high_prio
      light: 'rgba(215,15,55,0.07)', // color-status-warning-high_prio 7 %
    },
    success: {
      main: '#3C992E', // color-status-success
      light: 'rgba(10,153,46,0.1)', // color-status-success 10 %
    },
  },
  typography: {
    fontFamily: fontFamily,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...defaultStyles(baseTheme.breakpoints),
        ...content(baseTheme.breakpoints),
        ...backgrounds(baseTheme.breakpoints),
        ...navigation(baseTheme.breakpoints),
        ...footer(baseTheme.breakpoints),
      },
    },

    MuiTypography: {
      defaultProps: {
        fontFamily: fontFamily,
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h3',
          subtitle2: 'h3',
          body1: 'p',
          body2: 'p',
        },
      },
      styleOverrides: {
        root: {
          color: secondaryLight,
          wordWrap: 'break-word',
          ...p(baseTheme.breakpoints),
          '&.left': {
            textAlign: 'left',
          },
          '&.center': {
            textAlign: 'center',
          },
          '&.right': {
            textAlign: 'right',
          },
          '&.primary': {
            color: primary,
          },
          '&.secondary': {
            color: secondary,
          },
          '&.accent': {
            color: white,
          },
          '&.copyright, &.disclaimer': {
            fontSize: '1rem',
          },
          h1: h1(baseTheme.breakpoints),
          h2: h2(baseTheme.breakpoints),
          h3: h3(baseTheme.breakpoints),
          h4: h4(baseTheme.breakpoints),
          h5: h5(baseTheme.breakpoints),
          h6: h6(baseTheme.breakpoints),
        },
        h1: h1(baseTheme.breakpoints),
        h2: h2(baseTheme.breakpoints),
        h3: h3(baseTheme.breakpoints),
        h4: h4(baseTheme.breakpoints),
        h5: h5(baseTheme.breakpoints),
        h6: h5(baseTheme.breakpoints),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          pointerEvents: 'auto',
          borderRadius: 0,
          borderWidth: 1,
          borderColor: 'transparent',
          padding: '0.75rem 0.75rem',
          margin: 0,
          lineHeight: 1.25,
          letterSpacing: 1.25,
          textAlign: 'center',
          width: 'fit-content',
          fontSize: '1rem',
          textTransform: 'uppercase',
          '&:hover, &:focus, &:active': {
            backgroundColor: primaryDark,
            color: white,
          },
          [baseTheme.breakpoints.up('md')]: {
            //fontSize: "1.5rem",
            //padding: "1.625rem 2rem",
          },
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '1.625rem',
            padding: '1.625rem 2rem',
          },
          '& svg': {
            background: 'transparent !important',
          },
          '&.primary': {
            backgroundColor: primary,
            color: white,
            '&:hover, &:active': {
              backgroundColor: 'transparent',
              color: primary,
            },
            '&:focus': {
              background: primary,
              color: white,
              textDecoration: 'none',
            },
          },
          '&.secondary': {
            color: black,
            backgroundColor: 'transparent',
            '&:hover, &:active': {
              backgroundColor: 'transparent',
              color: primary,
            },
            '&:focus': {
              backgroundColor: 'transparent',
              color: black,
              textDecoration: 'none',
            },
          },
        },
        text: {
          backgroundColor: 'transparent',
          padding: '0 !important',
          fontStyle: 'italic',
          '&:hover, &:active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            color: primary,
          },
          '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            color: primary,
          },
        },
      },
      variants: [],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderRadius: 0,
          color: white,
          '&.active': {
            color: white,
            backgroundColor: 'transparent',
          },
          '&:hover,&:focus': {
            color: white,
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          justifySelf: 'flex-end',
        },
        container: {
          background: 'rgba(0,0,0,0.8)',
        },
        paper: {
          padding: 0,
          margin: 0,
          background: 'transparent',
          borderRadius: 0,
          height: '100%',
          maxHeight: 'unset',
          width: '100%',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
          '.imagelist': {
            '.container-elements': {
              marginTop: '-1px',
              marginLeft: '-1px',
            },
            '.image': {
              outline: '1px solid #707070',
              marginTop: '1px',
              marginLeft: '1px',
            },
          },
        },
        maxWidthXl: {
          paddingLeft: '0',
          paddingRight: '0',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          paddingRight: '0 !important',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        fontFamily: fontFamily,
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          lineHeight: 1.2,
          cursor: 'pointer',
        },
      },
      variants: [],
    },
    MuiDivider: {
      styleOverrides: {
        root: {},
        vertical: {},
      },
    },
  },
});
