import React from 'react';
import './LogoAnimated.css';

export default function LogoAnimated(props: { animate?: boolean }) {
  const welcomeLogo = props.animate
    ? {
        transformOrigin: 'center',
        transform: 'scale(2.5)',
        opacity: 0,
        animation: 'bump_in 0.8s ease-out 1s forwards',
      }
    : {
        transform: 'scale(1)',
        opacity: 1,
      };

  const area = props.animate
    ? {
        opacity: 0,
        transform: 'matrix( 1, 0, 0, 1, 0, 200)',
        animation: 'ov_slide_in 0.8s ease 1s forwards',
      }
    : {
        opacity: 1,
        transform: 'matrix( 1, 0, 0, 1, 0, 0)',
      };

  return (
    <svg
      style={welcomeLogo}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 200 200"
    >
      <defs>
        <g id="Mask_6_MASK_0_FILL">
          <path
            fill="#FFFFFF"
            stroke="none"
            d="
M 183.4 48.9
Q 183.3 48.2 182.7 47.6 167.6 32.4 148.7 13.4 148.1 12.8 147.2 12.6 118.1 4.7 100.7 0.1 100.1 -0.1 99.3 0.1 77 6.1 62.7 9.9 57.1 11.4 52.8 12.6 51.9 12.8 51.3 13.4 34.3 30.4 17.4 47.5 16.7 48.1 16.5 48.9 9.5 75.1 4.1 95.4 3.9 96.3 4.1 97.2 10 119.2 16.5 143.7 16.8 144.5 17.4 145.1 36.2 164 51.3 179.1 52 179.8 52.8 180 58 181.4 62.7 182.7 85 188.7 99.1 192.5 100 192.7 100.8 192.5 121.4 187 147.2 180 148 179.8 148.7 179.2 163.8 164.1 182.7 145.1 183.3 144.4 183.4 143.8 184.4 140.1 187.4 128.8
L 195.9 97.1
Q 196.1 96.3 195.9 95.5 191.7 80 183.4 48.9 Z"
          />
        </g>

        <g id="Layer6_0_FILL">
          <path
            fill="#EBD593"
            stroke="none"
            d="
M 183.4 48.9
Q 183.3 48.2 182.7 47.6 167.6 32.4 148.7 13.4 148.1 12.8 147.2 12.6 118.1 4.7 100.7 0.1 100.1 -0.1 99.3 0.1 77 6.1 62.7 9.9 57.1 11.4 52.8 12.6 51.9 12.8 51.3 13.4 34.3 30.4 17.4 47.5 16.7 48.1 16.5 48.9 9.5 75.1 4.1 95.4 3.9 96.3 4.1 97.2 10 119.2 16.5 143.7 16.8 144.5 17.4 145.1 36.2 164 51.3 179.1 52 179.8 52.8 180 58 181.4 62.7 182.7 85 188.7 99.1 192.5 100 192.7 100.8 192.5 121.4 187 147.2 180 148 179.8 148.7 179.2 163.8 164.1 182.7 145.1 183.3 144.4 183.4 143.8 184.4 140.1 187.4 128.8
L 195.9 97.1
Q 196.1 96.3 195.9 95.5 191.7 80 183.4 48.9 Z"
          />
        </g>

        <g id="area" style={area}>
          <path
            fill="#000000"
            stroke="none"
            d="
M 183.5 56.9
Q 183.4 56.2 182.8 55.6 167.7 40.4 148.8 21.4 148.2 20.8 147.3 20.6 118.2 12.7 100.8 8.1 100.2 7.9 99.4 8.1 77.1 14.1 62.8 17.9 57.2 19.4 52.9 20.6 52 20.8 51.4 21.4 34.4 38.4 17.5 55.5 16.8 56.1 16.6 56.9 9.6 83.1 4.2 103.4 4 104.3 4.2 105.2 10.1 127.2 16.6 151.7 16.9 152.5 17.5 153.1 36.3 172 51.4 187.1 52.1 187.8 52.9 188 58.1 189.4 62.8 190.7 73.4 193.5 82.1 195.9 91.8 198.5 99.2 200.5 100.1 200.7 100.9 200.5 109.1 198.3 118.1 195.9 131.7 192.2 147.3 188 148.1 187.8 148.8 187.2 163.9 172.1 182.8 153.1 183.4 152.4 183.5 151.8 184.5 148.1 187.5 136.8
L 196 105.1
Q 196.2 104.3 196 103.5 191.8 88 183.5 56.9 Z"
          />
        </g>

        <g id="Layer3_0_FILL">
          <path
            fill="#EBD593"
            stroke="none"
            d="
M 112.3 43
L 99.7 35.5
Q 79.2 47.7 72.8 51.5 72.6 51.7 72.2 51.8 71.8 51.8 71.5 51.7 70.8 51.5 62.7 48.4 58.9 46.9 53.4 44.8
L 44.5 41.4 44.5 74 155 74 155 41.5 128.1 51.7
Q 127.8 51.8 127.4 51.8 127 51.7 126.7 51.5 122.7 49.2 112.3 43 Z"
          />
        </g>

        <g id="Layer2_0_FILL">
          <path
            fill="#EBD593"
            stroke="none"
            d="
M 155 79.5
L 121.8 79.5 121.8 156.4 122.2 156.4 154.2 145.8
Q 154.7 145.6 154.9 145.3 155 145.1 155 144.6
L 155 79.5 Z"
          />
        </g>

        <g id="Layer1_0_FILL">
          <path
            fill="#EBD593"
            stroke="none"
            d="
M 116.3 79.5
L 83.2 79.5 83.2 156.5 116.3 156.5 116.3 79.5 Z"
          />
        </g>

        <g id="Layer0_0_FILL">
          <path
            fill="#EBD593"
            stroke="none"
            d="
M 77.6 79.5
L 44.5 79.5 44.5 145.5 77.6 156.5 77.6 79.5 Z"
          />
        </g>
      </defs>

      <mask id="Mask_Mask_1">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use href="#Mask_6_MASK_0_FILL" />
        </g>
      </mask>

      <g mask="url(#Mask_Mask_1)">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use href="#Layer6_0_FILL" />
        </g>
      </g>

      <g mask="url(#Mask_Mask_1)">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use href="#area" />
        </g>
      </g>

      <g transform="matrix( 1, 0, 0, 1, 0,0) ">
        <use href="#Layer3_0_FILL" />
      </g>

      <g transform="matrix( 1, 0, 0, 1, 0,0) ">
        <use href="#Layer2_0_FILL" />
      </g>

      <g transform="matrix( 1, 0, 0, 1, 0,0) ">
        <use href="#Layer1_0_FILL" />
      </g>

      <g transform="matrix( 1, 0, 0, 1, 0,0) ">
        <use href="#Layer0_0_FILL" />
      </g>
    </svg>
  );
}
