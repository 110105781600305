import React, { ComponentProps, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Breakpoint, Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import BackgroundImage from './BackgroundImage';

type Props = {
  backgrounds: ComponentProps<typeof BackgroundImage>[];
  activeSection?: string;
  sx?: SxProps<Theme>;
  breakpoint: Breakpoint;
  mode: 'slide' | 'fade';
};

function getDivId(activeSectionId?: string) {
  return !activeSectionId || activeSectionId === '' ? 'welcome-bg' : `${activeSectionId}-bg`;
}

function getTopPosition(divId: string) {
  const el: HTMLElement | null = document.getElementById(divId);
  if (el) {
    return -el.offsetTop;
  }
  return 1;
}

export default function Backgrounds(props: Props): React.JSX.Element {
  const { sx, backgrounds, activeSection, breakpoint, mode } = props;
  const [top, setTop] = useState(getTopPosition(getDivId(activeSection)));
  const ref = useRef(null);

  const [actualDivId, setActualDivId] = useState<string | undefined>('')

  useEffect(() => {
    const bg = backgrounds.find((b) => b.id === activeSection)
    console.log('default - activeSection setActualDivId: ', bg)
    if(bg) {
      setActualDivId(activeSection)
    }
  }, [activeSection, backgrounds]);

  const divId = useMemo(() => {
    console.log('default - activeSection: ', activeSection, getDivId(activeSection))

    return getDivId(activeSection);
  }, [activeSection]);

  useEffect(() => {
    if (mode === 'slide') setTop(getTopPosition(divId));
  }, [divId, mode]);

  if (mode === 'slide') {
    return (
      <Stack
        id={'bg'}
        ref={ref}
        flexDirection={'column'}
        sx={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: top,
          transition: 'top 1s ease-in-out',
          ...sx,
        }}
      >
        {activeSection && backgrounds.map((bg) => <BackgroundImage {...bg} breakpoint={breakpoint} />)}
      </Stack>
    );
  } else {
    return (
      <Box
        id={'bg'}
        ref={ref}
        sx={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          opacity: 0,
          transition: 'opacity 1s ease-in-out',
          animation: 'fadeIn 2s ease 1s forwards',
          ...sx,
        }}
      >
        {actualDivId &&
          backgrounds.map((bg) => (
            <BackgroundImage
              {...bg}
              breakpoint={breakpoint}
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                transition: 'opacity 1s ease-in-out',
                //opacity: (activeSection === bg.id || (activeSection === "footer" && bg.id === backgrounds.at(-2)?.id)) ? 1 : 0
                opacity: actualDivId === bg.id ? 1 : 0,
              }}
            />
          ))}
      </Box>
    );
  }
}
