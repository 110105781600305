import React, { PropsWithChildren, useMemo } from 'react';
import { Box } from '@mui/material';
import { ccn } from '../utils/utils';

export default function PostBackground(
  props: PropsWithChildren<{
    variant?: 'primary' | 'secondary';
    className?: string;
  }>
): React.JSX.Element {
  const angle = useMemo(() => {
    return (0.125 + Math.random() * 0.125) * (Math.random() > 0.5 ? 1 : -1);
  }, []);

  const d = '0.25rem';

  return (
    <Box
      className={ccn(['background', props.className])}
      sx={{
        display: 'flex',
        background: 'transparent',
        position: 'relative',
      }}
    >
      <Box
        className={'bg-box-outer'}
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          transform: `rotate(-${angle}deg)`,
        }}
      />
      <Box
        className={'bg-box-inner'}
        sx={{
          left: d,
          right: d,
          top: d,
          bottom: d,
          position: 'absolute',
          transform: `rotate(+${angle}deg)`,
        }}
      />
      {props.children}
    </Box>
  );
}
