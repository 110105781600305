import React from 'react';

export default function BottomCrown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="xMinYMax meet"
      x="0px"
      y="0px"
      width="1920px"
      height="145px"
      viewBox="0 0 1920 145"
    >
      <g id="Layer1_0_FILL">
        <path
          fill="#000000"
          stroke="none"
          d="
M 1920 145
L 1920 34.1
Q 1920 34.35 1442.75 98.15
L 960.45 0 480.35 98.15
Q 359.5 82.15 239 66.1 -1.95 34.1 0 34.1
L 0 145 1920 145 Z"
        />
      </g>
    </svg>
  );
}
