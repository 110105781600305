import React, { useState } from 'react';
import { BlockVideo } from '../../types/Block';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { KirbyProps } from '@gk-lab/kirbyreact';
import PostBackground from '../PostBackground';
import { ccn } from '../../utils/utils';

interface Props extends KirbyProps {
  data: BlockVideo;
}

export default function Video(props: Props): React.JSX.Element {
  const { data, sx } = props;
  const { videourl, title, subtitle, text } = data;
  const [showDisclaimer, setShowDisclaimer] = useState(Boolean(data?.videourl));

  return (
    <Box
      className={ccn(['video', props.className])}
      sx={{
        ...sx,
      }}
    >
      <PostBackground>
        <Stack className={'wrapper'} sx={{ width: '100%' }}>
          <Stack className={'content'} sx={{ width: '100%' }}>
            {subtitle && (
              <Typography variant={'h5'} className={'subtitle'}>
                {subtitle}
              </Typography>
            )}

            <Typography variant={'h3'} className={'title'}>
              {title}
            </Typography>

            {text && <Typography className={'text'} dangerouslySetInnerHTML={{ __html: text }} />}

            {showDisclaimer ? (
              <Stack
                sx={{
                  position: 'relative',
                  zIndex: 300,
                  flexDirection: 'column',
                  minHeight: '320px',
                  width: '100%',
                  aspectRatio: '16 / 9',
                  border: '1px solid #ffffff',
                  padding: '1rem',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <Typography>
                  Mit dem Laden des Videos akzeptierst du die{' '}
                  <Link href={'https://policies.google.com/privacy?hl=de'} target={'_blank'}>
                    Datenschutzerklärung von youtube
                  </Link>
                  .
                </Typography>
                <Button variant={'outlined'} onClick={() => setShowDisclaimer(false)}>
                  Video laden
                </Button>
              </Stack>
            ) : (
              <iframe
                style={{ zIndex: 300 }}
                width="100%"
                height="315"
                src={videourl}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            )}
          </Stack>
        </Stack>
      </PostBackground>
    </Box>
  );
}
