import React from 'react';

export default function Dodecagon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      width="96px"
      height="96px"
      viewBox="0 0 96 96"
    >
      <g id="circle">
        <path
          fill="#EBD593"
          stroke="none"
          d="
M 95.95 47.7
Q 93.85 39.95 89.7 24.4 89.65 24.05 89.35 23.75 81.8 16.15 72.35 6.65 72.05 6.35 71.6 6.25 57.05 2.3 48.35 0 48.05 -0.1 47.65 0 36.5 3 29.35 4.9 26.55 5.65 24.4 6.25 23.95 6.35 23.65 6.65 15.15 15.15 6.7 23.7 6.35 24 6.25 24.4 2.75 37.5 0.05 47.65 -0.05 48.1 0.05 48.55 3 59.55 6.25 71.8 6.4 72.2 6.7 72.5 16.1 81.95 23.65 89.5 24 89.85 24.4 89.95 27 90.65 29.35 91.3 40.5 94.3 47.55 96.2 48 96.3 48.4 96.2 58.7 93.45 71.6 89.95 72 89.85 72.35 89.55 79.9 82 89.35 72.5 89.65 72.15 89.7 71.85 90.2 70 91.7 64.35
L 95.95 48.5
Q 96.05 48.1 95.95 47.7 Z"
        />
      </g>
    </svg>
  );
}
