import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { KirbyProps } from '@gk-lab/kirbyreact';
import PostBackground from '../PostBackground';
import { BlockEvent } from '../../types/Block';
import { ccn } from '../../utils/utils';

interface Props extends KirbyProps {
  data: BlockEvent;
}

export default function Event(props: Props): React.JSX.Element {
  const { data } = props;
  const { variant: eventVariant, today, title, subtitle, text, date } = data;

  const variant = useMemo(() => {
    if (today) {
      return 'primary';
    }
    return Boolean(eventVariant?.trim()) ? eventVariant : 'secondary';
  }, [today, eventVariant]);

  return (
    <Box className={ccn(['event', variant, props.className])}>
      <PostBackground className={ccn([variant])}>
        <Stack className={'content'}>
          <Typography variant={'h3'} className={'date'}>
            {date}
          </Typography>
          {subtitle && (
            <Typography variant={'h5'} className={'subtitle'}>
              {data?.subtitle}
            </Typography>
          )}
          <Typography variant={'h3'} className={'title'}>
            {title}
          </Typography>
          {text && <Typography className={'text'} dangerouslySetInnerHTML={{ __html: text }} />}
        </Stack>
      </PostBackground>
    </Box>
  );
}
