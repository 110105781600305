import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { IKirbySection, KirbyProps } from '@gk-lab/kirbyreact';
import { useInView } from 'react-intersection-observer';
import HomeView from '../views/HomeView';
import { SectionDefault } from '../types/Section';

interface Props extends KirbyProps {
  matchLocation?: boolean;
  sectionData: IKirbySection;
  onVisible?(sectionId: string, value: boolean): void;
  className?: string;
  scrollLink?: string;
  animate?: boolean;
}

export default function HomePage(props: Props) {
  const { sectionData, scrollLink, animate, onVisible, className, sx } = props;

  const { ref, inView: isVisible } = useInView({
    threshold: 0.3,
  });

  useEffect(() => {
    if (onVisible) onVisible(sectionData.url, isVisible);

    if (isVisible) {
      document.title = `MUSIC MONKS - ${sectionData.title.toUpperCase()}`;
    }
  }, [sectionData.url, sectionData.title, isVisible, onVisible]);

  return (
    <Box
      component="section"
      ref={ref}
      id={`${sectionData.url}-page`}
      className={['page', `${sectionData.url.replace('/', '-')}`, className].filter((cn) => Boolean(cn)).join(' ')}
      sx={{
        position: 'relative',
        width: '100%',
        ...sx,
      }}
    >
      <HomeView showAnimation={animate} data={sectionData as SectionDefault} scrollLink={scrollLink} />
    </Box>
  );
}
