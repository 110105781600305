import { Breakpoints, CSSObject } from '@mui/material';
import { black, primary, white } from '../Theme';

export const footer = (breakpoints: Breakpoints): CSSObject => ({
  footer: {
    background: black,
    p: {
      fontSize: '1rem !important',
      textTransform: 'uppercase',
      marginBottom: '0 !important',
      padding: '0.25rem !important',
    },
    '.button-container': {
      width: 'fit-content !important',
    },
    'a.footer': {
      fontSize: '1rem',
      width: 'fit-content !important',
      padding: '0.25rem !important',
      textDecoration: 'underline',
      color: `${white} !important`,
      background: 'transparent',
      '&.active, &.focus': {
        background: 'transparent',
        color: `${primary} !important`,
      },
    },
  },
});
