import { Breakpoints, CSSObject } from '@mui/material';
import { primary } from '../Theme';

export const h1 = (breakpoints: Breakpoints): CSSObject => ({
  textTransform: 'uppercase',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: 1,
  position: 'relative',
  [breakpoints.up('sm')]: {},
  [breakpoints.up('md')]: {},
  [breakpoints.up('lg')]: {},
  [breakpoints.up('xl')]: {},
});
export const h2 = (breakpoints: Breakpoints): CSSObject => ({
  textTransform: 'uppercase',
  fontSize: '2.5rem',
  fontWeight: 700,
  lineHeight: 1,
  letterSpacing: 2,
  [breakpoints.up('sm')]: {
    fontSize: '3rem',
  },
  [breakpoints.up('md')]: {
    fontSize: '4rem',
  },
  [breakpoints.up('lg')]: {
    fontSize: '6rem',
  },

  '&.primary': {
    color: primary,
  },

  '&.welcome-title': {
    transition: 'opacity 0.3s',
    textAlign: 'right',
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: 2,

    [breakpoints.up('sm')]: {
      fontSize: '6rem',
    },
    [breakpoints.up('md')]: {
      fontSize: '7rem',
    },
    [breakpoints.up('lg')]: {
      fontSize: '8rem',
    },
  },

  /*
    [breakpoints.up("xl")]: {
      fontSize: "5rem"
    },*/
});

export const h3 = (breakpoints: Breakpoints): CSSObject => ({
  fontSize: '2rem',
  fontWeight: 700,
  lineHeight: 1,
  letterSpacing: 2,
  textTransform: 'uppercase',
  position: 'relative',
  [breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
  [breakpoints.up('md')]: {
    //fontSize: "3rem",
  },
  [breakpoints.up('lg')]: {
    lineHeight: 1.125,
    fontSize: '2.5rem',
  },
  [breakpoints.up('xl')]: {},
});

export const h4 = (breakpoints: Breakpoints): CSSObject => ({
  fontSize: '1.5rem',
  fontWeight: 500,
  lineHeight: 1,
  position: 'relative',
  [breakpoints.up('sm')]: {
    fontSize: '2.25rem',
  },
  [breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
  [breakpoints.up('lg')]: {
    fontSize: '2.75rem',
  },
  [breakpoints.up('xl')]: {
    fontSize: '3rem',
  },
});

export const h5 = (breakpoints: Breakpoints): CSSObject => ({
  fontSize: '1.5rem',
  fontWeight: 400,
  lineHeight: 1,
  position: 'relative',
  [breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
});

export const h6 = (breakpoints: Breakpoints): CSSObject => ({
  fontSize: '1rem',
  transform: 'none',
  fontWeight: 400,
  marginBottom: '0.25rem',
  marginTop: '0.25rem',
  [breakpoints.up('lg')]: {
    fontSize: '1.25rem',
  },
});
