import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IKirbyImage } from '../../../.yalc/@gk-lab/kirbyreact';
import { useNavigate, useParams } from 'react-router-dom';
import SwipeContainer from '../SwipeContainer/SwipeContainer';
import { Box, Button, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import ImageFooter from './ImageFooter';
import Loader from '../../svg/Loader';

interface Props {
  images?: IKirbyImage[];
  path?: string;
}

export function GalleryImage(props: Props): React.JSX.Element {
  const { images, path } = props;
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const image: IKirbyImage | undefined = useMemo(() => {
    setLoading(true);
    const img = images?.find((img) => img.name === params.id);
    return img;
  }, [params.id, images]);

  const nextImage: string | undefined = useMemo(() => {
    let value: string | undefined = undefined;
    images?.forEach((img, idx) => {
      if (img.name === params.id) {
        if (idx < images?.length - 1) {
          value = images[idx + 1].name;
        } else {
          value = images[0].name;
        }
      }
    });
    return value;
  }, [images, params.id]);

  const prevImage: IKirbyImage | undefined = useMemo(() => {
    let value: string | undefined = undefined;
    images?.forEach((img, idx) => {
      if (img.name === params.id) {
        if (idx > 0) {
          value = images[idx - 1].name;
        } else {
          value = images[images?.length - 1].name;
        }
      }
    });
    return value;
  }, [images, params.id]);

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          if (prevImage) {
            navigate(`/${path}/${prevImage}`, { replace: true });
          }
          break;
        case 'ArrowRight':
          if (nextImage) {
            navigate(`/${path}/${nextImage}`, { replace: true });
          }
          break;
      }
    },
    [prevImage, nextImage, path, navigate]
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKey);
    return () => {
      window.removeEventListener('keyup', handleKey);
    };
  }, [image, handleKey]);

  return (
    <SwipeContainer
      className={'p-gallery-image'}
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        backgroundColor: 'black',
      }}
      leftPath={nextImage && `/${path}/${nextImage}`}
      rightPath={prevImage && `/${path}/${prevImage}`}
      replace={true}
    >
      {/*<ActionBar fallbackTo={path ? `/${path}` : "/home"} />*/}
      {image && (
        <>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={image.url}
              alt={image.alt ? image.alt : image.name}
              onLoad={() => setLoading(false)}
              sx={{
                width: 'auto',
                height: '100%',
                visibility: loading ? 'hidden' : 'visible',
                margin: 'auto 0',
              }}
              aria-label={image.alt ? image.alt : image.name}
            />
            {loading && (
              <Stack
                sx={{
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: '100%',
                  height: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pointerEvents: 'none',
                }}
              >
                <Loader />
              </Stack>
            )}
          </Box>
          {(image.photographer || image.caption) && (
            <ImageFooter
              photographer={image.photographer}
              link={image.link}
              caption={image.caption}
              sx={{
                position: 'absolute',
                left: '1rem',
                bottom: '1rem',
              }}
            />
          )}
          <Stack
            sx={{
              position: 'absolute',
              bottom: '2rem',
              top: 0,
              //left: 0,
              right: 0,
              gap: 2,
              padding: 2,
              justifyContent: 'flex-end',
              //alignContent: "flex-end",
              alignItems: 'flex-end',
            }}
          >
            {prevImage && (
              <Button
                className={'gallery-button previous'}
                component={RouterLink}
                replace={true}
                relative={'route'}
                to={`/${path}/${prevImage}`}
                startIcon={<ArrowBackIosNew />}
                aria-label="Letztes Bild"
                sx={{
                  width: 'fit-content',
                }}
              />
            )}
            {nextImage && (
              <Button
                className={'gallery-button next'}
                component={RouterLink}
                replace={true}
                relative={'route'}
                to={`/${path}/${nextImage}`}
                startIcon={<ArrowForwardIos />}
                aria-label="Nächstes Bild"
                sx={{
                  width: 'fit-content',
                }}
              />
            )}
          </Stack>
        </>
      )}
    </SwipeContainer>
  );
}
