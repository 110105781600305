import React, { ComponentProps, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface Props extends ComponentProps<typeof Link> {}

export default function SocialLink(props: PropsWithChildren<Props>): React.JSX.Element {
  const { children, ...rest } = props;
  return (
    <Link className={'link social'} target={'_blank'} rel="noopener" {...rest}>
      {children}
    </Link>
  );
}
