import React, { useEffect, useState } from 'react';
import { Stack, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import Loader from '../svg/Loader';

type Props = {
  sx?: SxProps<Theme>;
  loaded: boolean;
};

export default function AppLoader(props: Props): React.JSX.Element | null {
  const { loaded } = props;

  const [hideMe, setHideMe] = useState(false);
  const [invisible, setInvisible] = useState(false);

  useEffect(() => {
    setHideMe(loaded);
    if (loaded) {
      setTimeout(() => setInvisible(true), 3000);
    }
  }, [loaded]);

  if (invisible) {
    return null;
  }

  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 0,
        bottom: '3rem',
        left: 0,
        right: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        animation: hideMe ? `fadeOut 1s ease-out 0s normal forwards` : 'none',
        pointerEvents: 'none',
      }}
    >
      <Loader />
      <Typography
        component={'p'}
        sx={{
          fontSize: '1rem',
          textTransform: 'uppercase',
          margin: '-1.5rem 0 0 0',
          padding: '0',
        }}
      >
        music-monks.com
      </Typography>
    </Stack>
  );
}
